import React, { useState, useEffect } from "react";
import { collection, deleteDoc, doc, updateDoc, arrayUnion, addDoc } from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { onSnapshot } from "firebase/firestore";
import "./pageStyles/blog.css";
import { Link, useNavigate } from "react-router-dom";
import loveicon from "../component/images/love_icon-removebg-preview.png";
import deleteicon from "../component/images/delete_icon-removebg-preview.png";
import Footer from "../component/Footer";


function Home({ isAuth }) {
  const [posts, setPosts] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const postsCollectionRef = collection(db, "posts");


  let navigate = useNavigate();




  const deletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, "posts", postId));
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      postsCollectionRef,
      (querySnapshot) => {
        const postArray = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          postArray.unshift({
            id: doc.id,
            title: data.title,
            postText: data.postText,
            imageUrl: data.imageUrl,
            author: data.author,
            loveCount: data.loveCount || 0,
            timestamp: data.timestamp, // Assuming you have a timestamp field in your Firestore documents
          });
        });
        setPosts(postArray);
      },
      (error) => {
        console.error("Error fetching posts:", error);
      }
    );

    return () => {
      unsubscribe(); // Unsubscribe when component unmounts
    };
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('https://newsdata.io/api/1/news?apikey=pub_388660357e698919937a2953670cb288dd0f3&q=pegasus&language=en');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLoading(false); 

        console.log("News Data:", data); // Log the news data to check if it's fetched correctly
        setNewsData(data.articles); // Assuming the news data is in the 'articles' field of the response
      } catch (error) {
        console.error('Error fetching news data:', error);
        setLoading(false); 
      }
    };
  
    fetchNews();
  }, []);
  

  const getCurrentDateTime = () => {
    const now = new Date();
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
    return now.toLocaleString('en-US', options);
  };

  const addPost = async () => {
    try {
      const currentDateTime = getCurrentDateTime();
      const newPost = {
        title: "New Post Title",
        postText: "This is a new post text.",
        imageUrl: "example.jpg",
        author: {
          id: auth.currentUser.uid,
          name: "Author Name",
        },
        loveCount: 0,
        timestamp: currentDateTime,
      };

      // Assuming you have a function to add a post to Firebase Firestore
      const docRef = await addDoc(postsCollectionRef, newPost);

      // Update local state with the new post
      setPosts((prevPosts) => [{ id: docRef.id, ...newPost }, ...prevPosts]);
    } catch (error) {
      console.error("Error adding post:", error);
    }
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleLoveClick = async (postId) => {
    try {
      const postRef = doc(db, "posts", postId);
      await updateDoc(postRef, {
        loveCount: arrayUnion(1),
      });
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, loveCount: post.loveCount + 1 } : post
        )
      );
    } catch (error) {
      console.error("Error updating love count:", error);
    }
  };

  return (
    <>
      <div className="blog-homePage">
        <div className="blog_navbar_head">
          <div onClick={() => navigate(-1)} className="cancel_button_blog">&lt;</div>
          <div className="create_blog">
            <Link to="/createpost">
              <button>Create Blog</button>         
            </Link>
          </div>
        </div>
        <div className="welcome_blog_text">
          <h1>Welcome to Our Blog!</h1>
          <p>Explore the world of beauty and premium tools with our community-driven blog. Here,
            you have the power to create and share your thoughts on everything related to beauty,
            top-notch accessories, and the latest trends in the beauty store.</p>
          <p>Join us in celebrating the essence of beauty and stay updated on the hottest topics in the beauty industry.</p>
        </div>
        {isAuth && (
          <div className="blogpost_container">
            {posts.map((post) => (
              <div key={post.id} className="blog_post_header">
                <div className="blog_flex">
                  {isAuth && post.author && post.author.id === auth.currentUser.uid && (
                    <button className="delete_post" onClick={() => deletePost(post.id)}>
                      {""}
                      <img src={deleteicon} alt="love_icon" width={20} />
                    </button>
                  )}
                </div>
                {post.imageUrl && (
                  <img className="blog_image" src={post.imageUrl} alt="Post-ph" loading="lazy" />
                )}
                <div className="love-button-container">
                  <button
                    className="love-button"
                    onClick={() => handleLoveClick(post.id)}
                  >
                    <img src={loveicon} alt="love_icon" width={20} />
                  </button>
                  <span className="love-count"> {post.loveCount} </span>
                </div>
                <h2 className="blog_title">{post.title}</h2>
                <p className="post_text">
                  {showFullText ? post.postText : post.postText.slice(0, 80)}
                  {post.postText.split(" ").length > 80 && (
                    <span>
                      <p onClick={toggleText}>
                        {showFullText ? "Read Less" : "Read More"}
                      </p>
                    </span>
                  )}
                </p>
                <p className="auhors_name">Author: {post.author && post.author.name}</p>
                {/* <p className="post_datetime">Posted on: {post.timestamp}</p> */}
              </div>






            ))}
          </div>
        )}
      </div>
      {!newsData && <p>Loading news data...</p>}
{newsData && (
  <div className="blog_footer1">
    <h2>Latest Crypto News</h2>
    <ul>
      {newsData.map((news, index) => (
        <li key={index}>
          <a href={news.url} target="_blank" rel="noopener noreferrer">
            {news.title}
          </a>
        </li>
      ))}
    </ul>
  </div>
)}



    </>
  );
}

export default Home;

