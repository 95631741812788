import React, { useContext } from 'react';
import { CartContext } from '../CartContext';

function Transaction({ transactionDetails }) {
  const cartContext = useContext(CartContext);

  if (!transactionDetails || !transactionDetails.userInfo) {
    return (
      <div className="transaction-details">
        <p>No transaction details available.</p>
      </div>
    );
  }

  const { downloadURL, userInfo, cartItems } = transactionDetails;

  return (                                                                                                                                                               
    <div className="transaction-details">
      <h2>Transaction Details</h2>
      <p>
        <strong>User Information:</strong>
        <br />
        Full Name: {userInfo.fullName || 'N/A'}
        <br />
        Email Address: {userInfo.emailAddress || 'N/A'}
        <br />
        Home Address: {userInfo.homeAddress || 'N/A'}
        <br />
        Phone Number: {userInfo.phoneNumber || 'N/A'}
      </p>

      <h3>Items in Your Cart</h3>
      <ul>
        {cartItems.map((item, index) => (
          <li key={index}>
            {item.quantity} x {item.name} - ${item.price ? item.price.toFixed(2) : 'N/A'}
          </li>
        ))}
      </ul>

      <p>
        <strong>Transaction Image:</strong>
        <br />
        <img src={downloadURL} alt="Transaction Receipt" style={{ maxWidth: '100%' }} />
      </p>
    </div>
  );
}

export default Transaction;
