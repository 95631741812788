import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'


// const OpenLink = () => {
//   window.open('https://paystack.shop/koksi-beauty-store');
// };

const images = [
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/wooden_brush.jpg?updatedAt=1694183940419',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR1.jpg?updatedAt=1694179906316',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/scissor.jpg?updatedAt=1694179906298',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush3.jpg?updatedAt=1694179906292',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush1.jpg?updatedAt=1694179906300',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush2.jpg?updatedAt=1694179906247',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/neck_paper.jpg?updatedAt=1694179906123',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR.jpg?updatedAt=1694179905932',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush.jpg?updatedAt=1694179905892',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/towel_warmer.jpg?updatedAt=1694179905668',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/CURL%20SPONGE.jpg?updatedAt=1694179648800',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper14.jpg?updatedAt=1694179358164',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper16.jpg?updatedAt=1694184243420',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper15.jpg?updatedAt=1694179357716',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/nose_clipper.jpg?updatedAt=1694179906377',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper18.jpg?updatedAt=1694180078668',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper1.jpg?updatedAt=1694184328038',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper17.jpg?updatedAt=1694179357288',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper2.jpg?updatedAt=1694179357021',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper3.jpg?updatedAt=1694179352430',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper13.jpg?updatedAt=1694179348590',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper6.jpg?updatedAt=1694179348556',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper4.jpg?updatedAt=1694179348491',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper10.jpg?updatedAt=1694179348491',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper12.jpg?updatedAt=1694179348456',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring1.jpg?updatedAt=1694433957192',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring3.jpg?updatedAt=1694433957109',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring2.jpg?updatedAt=1694433957097',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring7.jpg?updatedAt=1694433956955',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring6.jpg?updatedAt=1694433956941',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring4.jpg?updatedAt=1694433956834',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/ring5.jpg?updatedAt=1694433956834',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/Vanclef.jpg?updatedAt=1695855915473',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/Unisex.jpg?updatedAt=1695855915411',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/Cartier.jpg?updatedAt=1695855915334',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/men.jpg?updatedAt=1695855915342',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/Anglet.jpg?updatedAt=1695855927472',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/bits.jpg?updatedAt=1695855928202',
  // 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/RingAccessories/Channel.jpg?updatedAt=1695855915648',
 

  // Add more image URLs here
];

const BarbingTools = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    }, 2000);



  
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div 
    className='random_image'>
      <h3>ACCESSORIES</h3>
      <NavLink to='/store'>
        <img  className='rusty-border' src={currentImage} alt="Random"  width={300} height={300} loading='lazy'/>
      </NavLink>

    </div>
  );
};

export default BarbingTools;



