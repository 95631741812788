import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

function NewsletterSignup() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();

    try {
      // Add the email to a Firebase collection
      const newsletterRef = collection(db, "newsletter");
      await addDoc(newsletterRef, { email });
      setSubscribed(true);
    } catch (error) {
      console.error("Error subscribing to the newsletter:", error);
    }
  };

  return (
    <div className="select_subscribe">
      {subscribed ? (
        <p>Thank you for subscribing! You'll receive updates in your email.</p>
      ) : (
        <form onSubmit={handleSubscribe}>
          <label>
            Enter Your Email:
            <div className="select_game_bt">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                placeholder='Type your Email'/>
            </div>
          </label>
          <div className="signIn_bt sub_buttons">
            <button type="submit">Subscribe</button>
          </div>

        </form>
      )}
    </div>
  );
}

export default NewsletterSignup;
