import { Link } from 'react-router-dom';


function Success() {
    return (
        <h1 className="success_puchase">Thank you for your purchase!  <button className='cancel_button'>
        <Link to='/'>
        Back to Home
        </Link>
        </button></h1>
    )
}

export default Success;