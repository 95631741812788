// import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles/card.css'
import 'firebase/firestore';


    
    
const BarberCard = ({ imageSrc, description, title, price, image_title, duration}) => {


  return (
    <div className="card_body">
        <div className="card_flex">
            <div className="card_Left">
                <div className="cardisImg">
                    <img src={imageSrc} alt={image_title} />
                    <div className="card-content">
                        <p className="card-title">{title}</p>
                        <p className="card-description">{description}</p>
                        <p className="card-price">{price}</p>
                        {/* <LikeButton /> */}
                        <p className="card-duration">{duration}</p>
                        <div className="book_appointment_button">
                            <NavLink to="/AirbnbHome">
                                <button>Add to Cart</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default BarberCard;
