import React, { useState } from 'react';
import './styles/home.css';
import { NavLink, Link, useNavigate} from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import NavbarComponent from './NavbarComponent';

const NavBar = ({ productsCount }) => {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'));
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      navigate('/'); 
    });
  };

  return (
    <>
      <div className="navbar-heading">
        <div className="home_nav">
          <div className="left_icon">
            <div className="menubar_icon">
              <NavLink to="/">
                <img
                  src="https://ik.imagekit.io/eitvb0lyq/koko%20canva%20(1).png?updatedAt=1698762961621"
                  alt="menubar"
                  loading="lazy"
                  width={80}
                />
              </NavLink>
              <span className="logo_text">Koksi Beauty Store</span>

              {!isAuth ? (
                <Link className="login_texts" to="/login">
                  {' '}
                  Login{' '}
                </Link>
              ) : (
                ''
              )}

              {!isAuth ? (
                ''
              ) : (
                <div className="destop_logout_button">
                  <button onClick={signUserOut}> Log Out </button>
                </div>
              )}
            </div>
          </div>

          <div className="right_icon_home">
            <div onClick={handleMenu} className="menu_divs">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="SERVICE_moble menu-popup ">
          <div onClick={handleCancel} className="can_box">
          &#60;
            </div>
          <div className="profile_setup">
            <NavLink to='/account'>
               <h3>My Profile <span className='profil-arrow'>&#62;</span></h3>
            </NavLink>
          </div>
          <div className="servicr_flex">
            <h2>OUR SERVICES</h2>
            {/* <div onClick={handleCancel} className="can_box">
            &#62;
            </div> */}
          </div>

          <NavLink to="/barbingsection">HAIRCUT  <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/bookingslip" >MANICURE <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/bookingslip">PEDICURE <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/store">SALES OF ACCESSORIES <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/nailsection">FIXING OF NAILS <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/bookingslip">PIERCING <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="bookingslip">HOME SERVICES <span className='profil-arrow'>&#62;</span></NavLink>
          <NavLink to="/barberstore">BARBING ACCESSORIES <span className='profil-arrow'>&#62;</span></NavLink>
             {/* Conditionally render the blog link */}
          {isAuth ? (
            <NavLink to="/blog"> OUR BLOG <span className='profil-arrow'>&#62;</span></NavLink>
          ) : (
            <Link className="login_texts" to="/login">
              OUR BLOG <span className='profil-arrow'>&#62;</span>
            </Link>
          )}
        </div>
      )}

   

      <NavbarComponent />
    </>
  );
};

export default NavBar;
