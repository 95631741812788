import React, { useState, useEffect } from "react";

function HomeFunctional({ isAuth, userName }) {
  const [showWelcome, setShowWelcome] = useState(false);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

    if (isAuth && !hasVisitedBefore) {
      setShowWelcome(true);

      localStorage.setItem("hasVisitedBefore", "true");

      const timer = setTimeout(() => {
        setShowWelcome(false);
      }, 
      5000); 

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isAuth]);

  return (
    <div className="homePage1">
      {isAuth && showWelcome && (
        <div className="welcome-message">
          <p>Welcome to Koksi Beauty Store, {userName || "Guest"}!</p>
        </div>
          )} 
    </div>
  );
}

export default HomeFunctional;
