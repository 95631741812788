import "./pageStyles/PortfolioSection.css"
import NavBar from "../component/NavBar1"
import Background from "../component/Background"
import Card from '../component/Card';
import { NavLink } from 'react-router-dom';
import Footer from '../component/Footer';
import Modal from 'react-modal';
import React, { useState } from 'react';
import ReferredUserLogin from "../component/ReferredUserLogin"






const PortfolioSection = () => {
 
  const [openRefer, setOpenRefer] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleOpenRefer = (
    
  ) =>{
  
      setOpenRefer(!openRefer)

  }

  return (
<>
<NavBar />
    <div className="porfolio_header">
      <div className="portfolio-section">
        <h5 className='head_text'>We offer a range of exeptional services tailored to meet your grooming needs.
          below are the service details including description, 
          pricing and duration for each of our services.</h5>
          { openRefer && (<div className="referal_login_absolute">
            <ReferredUserLogin />
          </div>) }
          
        <div className="carouselCenter">
        </div>
      <div className="card_heading">
        <div className="main_card_flex">
          <div className="card_left">
              <Card
                imageSrc="images/90s_cut.jpg" 
                title="90s Haircut"
                description="Recall the iconic flat top haircut that gained popularity in the 1990s?"
                duration="Duration: 50 minutes"
                price="Price: #5,000"             
                onCardClick={openModal}
              />
                <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Gold.jpg?updatedAt=1698857232511"
                  title="Yellow Gold"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #7,000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/mohawk_dreadlocks.jpg" 
                title="Mohawk Dreadlocks"
                description="For a unique twist on dreads, try a mohawk—a 
                captivating choice to stand out distinctively."
                duration="Duration: 50 minutes"
                price="Price: #20,000"  
                onCardClick={openModal}           
              />
              <Card
                imageSrc="images/short_afro.jpg" 
                title="Short Afro with Temple Fade"
                description="Discover an amazing low skin fade, complemented by a 
                sculpted afro top and sharp sides."
                duration="Duration: 50 minutes"
                price="Price: #5000"             
                onCardClick={openModal}
              />
           <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/lawn%20green.jpg?updatedAt=1698846110288"
                  title="Light Green"
                  description="Popular among Afro-American hairstyles, 
                  cornrows control curly hair, especially paired with a sleek buzz.."
                  duration="Duration: 50 minutes"
                  price="Price: #12,000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/short_wavy.jpg" 
                title="Short, Edgy and Wavy"
                description="Short hair gains allure with trendy cut, contrasting lines, 
                and enhancing natural texture for black men.."
                duration="Duration: 50 minutes"
                price="Price: #4,000"      
                onCardClick={openModal}
              />
              <Card
                imageSrc="images/temple.jpg" 
                title="Male African Temple Fade"
                description="Fades, a classic choice for men, offer a clean, sophisticated look, 
                ideal for busy schedules."
                duration="Duration: 40 minutes"
                price="Price: #4,000"  
                onCardClick={openModal}
              />
              <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Crimson.jpg?updatedAt=1698846073833"
                  title="Wine Color"
                  description="Popular among Afro-American hairstyles, 
                  cornrows control curly hair, especially paired with a sleek buzz.."
                  duration="Duration: 50 minutes"
                  price="Price: #12,000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/twisted.jpg" 
                title="Twisted High Top"
                description="Elevate textured, long locks with creative designs for an explosive hair statement, 
                igniting vibrant energy."
                duration="Duration: 40 minutes"
                price="Price: #6,000"    
                onCardClick={openModal}
              />
              <Card
                imageSrc="images/under_cut.jpg" 
                title="African Undercut"
                description="Embrace hair freedom; cultivate lengthy locks for a daring, confidence-boosting transformation. 
                Tame frizz with proper care."
                duration="Duration: 40 minutes"
                price="Price: #8,000" 
                onCardClick={openModal}
              />
              <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/deep%20pink.jpg?updatedAt=1698846074029"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/wave_design.jpg" 
                title="Waves with Hair Design"
                description="If fades aren't your style, consider bold, stylish buzz-cut waves to 
                enhance natural hair texture."
                duration="Duration: 40 minutes"
                price="Price: #3000"   
                onCardClick={openModal}
              />
              <div className="refer_button refer_button1">
                <button  onClick={handleOpenRefer}>Refer a friend and get <span className='twenty'>20%</span>discount</button>
            </div>
          </div>
          <div className="card_Right">
            <div className="refer_button">
                <button  onClick={handleOpenRefer}>Refer a friend and get <span className='twenty'>20%</span>discount</button>
            </div>
              <Card
                imageSrc="images/burst.jpg"
                title="Burst"
                description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
              <Card
                imageSrc="images/color_mohawk.jpg"
                title="Colored Mohawk"
                description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                  especially on young African men."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
           <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/light%20pink.jpg?updatedAt=1698846111781"
                  title="Flat Top with Shaved Designs"
                  description="Defy expectations with unique etched designs in black men's hair,
                   reflecting personal style and creativity.."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/cornrows.jpg"
                title="Cornrows"
                description="Popular among Afro-American hairstyles, 
                cornrows control curly hair, especially paired with a sleek buzz.."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
              <Card
                imageSrc="images/curly.jpg"
                title="Curly"
                description="Popular among Afro-American hairstyles, 
                cornrows control curly hair, especially paired with a sleek buzz.."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
               <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Fuchsia.jpg?updatedAt=1698846110501"
                  title="Man Bun"
                  description="Embrace the trend with a man bun, a 
                  standout among long black men's hairstyles."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/flat_top1.jpg"
                title="Flat Top with Shaved Designs"
                description="Defy expectations with unique etched designs in black men's hair,
                  reflecting personal style and creativity.."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
              <Card
                imageSrc="images/flat_top2.jpg"
                title="Flat Top"
                description="Defy expectations with unique etched designs in black men's hair,
                  reflecting personal style and creativity.."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
              <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Rust.jpg?updatedAt=1698846110306"
                  title="Flat Top"
                  description="Defy expectations with unique etched designs in black men's hair,
                   reflecting personal style and creativity.."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                  onCardClick={openModal}
                />
              </div>
              <Card
                imageSrc="images/man_bun.jpg"
                title="Man Bun"
                description="Embrace the trend with a man bun, a 
                standout among long black men's hairstyles."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
              
              <Card
                imageSrc="images/high_top.jpg"
                title="Black Boxy High Top Cut"
                description="Certain black male styles require minimal grooming, 
                like the retro high-top and full beard combo."
                duration="Duration: 50 minutes"
                price="Price: #3000"
                onCardClick={openModal}
              />
           <div className="hidedextop">
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/maroon.jpg?updatedAt=1698846110470"
                  title="Black Boxy High Top Cut"
                  description="Certain black male styles require minimal grooming, 
                  like the retro high-top and full beard combo."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                  onCardClick={openModal}
                />
              </div>
          </div>
          <div className="hideMobile">
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Gold.jpg?updatedAt=1698857232511"
              title="Burst"
              description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/deep%20pink.jpg?updatedAt=1698846074029"
              title="Colored Mohawk"
              description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                especially on young African men."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Crimson.jpg?updatedAt=1698846073833"
              title="Cornrows"
              description="Popular among Afro-American hairstyles, 
              cornrows control curly hair, especially paired with a sleek buzz.."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/lawn%20green.jpg?updatedAt=1698846110288"
              title="Curly"
              description="Popular among Afro-American hairstyles, 
              cornrows control curly hair, especially paired with a sleek buzz.."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/light%20pink.jpg?updatedAt=1698846111781"
              title="Flat Top with Shaved Designs"
              description="Defy expectations with unique etched designs in black men's hair,
                reflecting personal style and creativity.."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Rust.jpg?updatedAt=1698846110306"
              title="Flat Top"
              description="Defy expectations with unique etched designs in black men's hair,
                reflecting personal style and creativity.."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Fuchsia.jpg?updatedAt=1698846110501"
              title="Man Bun"
              description="Embrace the trend with a man bun, a 
              standout among long black men's hairstyles."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
            <Card
              imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/maroon.jpg?updatedAt=1698846110470"
              title="Black Boxy High Top Cut"
              description="Certain black male styles require minimal grooming, 
              like the retro high-top and full beard combo."
              duration="Duration: 50 minutes"
              price="Price: #3000"
              onCardClick={openModal}
            />
          </div> 
        </div>
        <div className="take_note">
          <h3>Notice!!!</h3>
            <p>Note that the pricing and duration mentioned above 
            are subject to change. Our Barber take pride in their craftmanship 
            and ensured each service is delivered with attention to detail and precision. 
            Visit us today to experience the art of grooming at its finest.</p>
          </div>
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        // overlayClassName="custom-overlay" 
        contentLabel="Full Image Modal"
        style={{
          overlay: {
            backgroundColor: '#0b0d1c',
          },
          content: {
            maxWidth: '800px', 
            margin: 'auto',
           height: '483px',
           background: '#0b0d1c',
           inset: '18px',
           
          },
        }}
      >
        <div className="z-index_modal">
          <button className="close_barber_modal" onClick={closeModal}>&lt;</button>
          <img
            src={selectedImage}
            alt="Full Image"
            style={{
              width: '100%',
              height: '70%',
              display: 'block',
              margin: '0 auto',
              position: 'relative',
              top: '52px',
             

            }}
          />
          <div className="book_appointment_button modal_absolut">
              <NavLink to="/bookingSlip">
                  <button>Book Now</button>
              </NavLink>
          </div>
        </div>
      </Modal>
      </div>
      </div>
      <div className="barber_store_footer">
      <Footer />
    </div>
    </div>
    
</>
  )
        }


        export default PortfolioSection;