// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA17KZQ-y-Iz6PdSNcUCTggPymcpvJWIv0",
  authDomain: "koksi-beauty-store-blog.firebaseapp.com",
  projectId: "koksi-beauty-store-blog",
  storageBucket: "koksi-beauty-store-blog.appspot.com",
  messagingSenderId: "156240721824",
  appId: "1:156240721824:web:3f6b4dcfd812c9791696c5",
  measurementId: "G-BWR3C4BQMS"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { app, db, auth, provider };
