import React, { useEffect, useState } from 'react';

const images = [
  './images/expertIcon2.jpg',
  '/images/expertIcon1.png',
  // Add more image URLs here
];

const ExpertIcon = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="team-of-expert">
    <h2>A TEAM <br /> <span>OF</span> EXPERTS</h2>
     <div 
        className='random_image barberImage team_cut'>
        <img src={currentImage} alt="Random" width={500}  />
    </div>
    <p>All of our Barbers, Nail Technicians and MakeUp Artist are 
      dedicated to creating an unrivaled experience 
      for every clients</p>
  </div>
   
  );
};

export default ExpertIcon;