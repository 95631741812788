import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';


const images = [
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail2.jpg?updatedAt=1694431420367',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail3.jpg?updatedAt=1694431420342',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail1.jpg?updatedAt=1694431420250',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail4.jpg?updatedAt=1694431420220',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail5.jpg?updatedAt=1694431420150',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nail%2017k.jpg?updatedAt=1708441026495',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails8%2017k.jpg?updatedAt=1708440959598',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails5%2015k.jpg?updatedAt=1708440959507',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails7%2026k.jpg?updatedAt=1708440959496',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails4%2026k.jpg?updatedAt=1708440956100',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails3%2023k.jpg?updatedAt=1708440953966',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Water%20Jail%20Acrylic%20nails%2020k.jpg?updatedAt=1708440942326',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal2%20jails%20both%2015k.jpg?updatedAt=1708440942283',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal1%20jail%20nails%208k.jpg?updatedAt=1708440942259',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20white%20tips%206k.jpg?updatedAt=1708440942229',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail2%209k.jpg?updatedAt=1708440942134',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%20nails%208k.jpg?updatedAt=1708440942053',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails%2018k.jpg?updatedAt=1708440940441',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails2%20with%20stones%2025k.jpg?updatedAt=1708440940425',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails1%2017k.jpg?updatedAt=1708440940081',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%20nails%207k.jpg?updatedAt=1708440937708',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails13%2010k.jpg?updatedAt=1708440928594',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20with%20stones%2025k.jpg?updatedAt=1708440928532',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%208k.jpg?updatedAt=1708440928529',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails14%2018k.jpg?updatedAt=1708440928534',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails15%2014k.jpg?updatedAt=1708440928529',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%2010k.jpg?updatedAt=1708440928488',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails10%20both%2018k.jpg?updatedAt=1708440928457',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails12%209k.jpg?updatedAt=1708440928453',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails11%2017k.jpg?updatedAt=1708440928329',

];

const Nail = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);

  // const [maintainance, setMaintainance] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);


  // const handleNotification = ( ) => {
  //   setMaintainance(true)

  //   setTimeout(() => {
  //     setMaintainance(false)
  //   }, 2000);
  // }


  return (
    <>
      <div 
      className='random_image'>
         {/* {maintainance && (
            <div className="account_maintainance">
            <h4>This Feature is under development</h4>
            </div>
        )} */}
        <h3>NAILS/PEDICURE/MANICURE</h3>
     <NavLink to="/nailsection">
        <img className='rusty-border' src={currentImage} alt="Random"  width={300} height={300} loading='lazy'/>
      </NavLink>
      </div>
    </>
  );
};

export default Nail;