import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/carousel.css";

function Carousel() {
  useEffect(() => {
    // Function to automatically slide the carousel
    const autoSlideCarousel = () => {
      const carousel = document.getElementById("carouselExampleAutoplaying");
      if (carousel) {
        const interval = 3000; // Set the interval in milliseconds (5 seconds in this case)
        let currentSlideIndex = 0;

        setInterval(() => {
          const slides = carousel.querySelectorAll(".carousel-item");
          slides[currentSlideIndex].classList.remove("active");
          currentSlideIndex = (currentSlideIndex + 1) % slides.length;
          slides[currentSlideIndex].classList.add("active");
        }, interval);
      }
    };

    autoSlideCarousel(); // Call the auto-slide function when the component mounts
  }, []);

  return (
    <>
      <div className="carousel_header">
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          {/* Your carousel items */}
          <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="imgFlex">
              <div className="before_image">
                <img src='//ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush2.jpg?updatedAt=1694179906247' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src= 'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush1.jpg?updatedAt=1694179906300' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="before_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/neck_paper.jpg?updatedAt=1694179906123' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="destop_images">
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/CURL%20SPONGE.jpg?updatedAt=1694179648800' className="d-block 300 imgsize" alt="caru1" />
                </div>
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/towel_warmer.jpg?updatedAt=1694179905668' className="d-block 300 imgsize inline_flex" alt="caru1" />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="imgFlex">
              <div className="before_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush3.jpg?updatedAt=1694179906292' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/scissor.jpg?updatedAt=1694179906298' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR.jpg?updatedAt=1694179905932' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="destop_images">
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper3.jpg?updatedAt=1694179352430' className="d-block 300 imgsize" alt="caru1" />
                </div>
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper1.jpg?updatedAt=1694184328038' className="d-block 300 imgsize inline_flex" alt="caru1" />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="imgFlex">
              <div className="before_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/wooden_brush.jpg?updatedAt=1694183940419' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR1.jpg?updatedAt=1694179906316' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush.jpg?updatedAt=1694179905892' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="destop_images">
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper17.jpg?updatedAt=1694179357288' className="d-block 300 imgsize" alt="caru1" />
                </div>
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper2.jpg?updatedAt=1694179357021' className="d-block 300 imgsize inline_flex" alt="caru1" />
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="imgFlex">
              <div className="before_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper3.jpg?updatedAt=1694179352430' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper13.jpg?updatedAt=1694179348590' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="after_image">
                <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper6.jpg?updatedAt=1694179348556' className="d-block 300 imgsize" alt="caru1" />
              </div>
              <div className="destop_images">
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper4.jpg?updatedAt=1694179348491' className="d-block 300 imgsize" alt="caru1" />
                </div>
                <div className="before_image">
                  <img src='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper10.jpg?updatedAt=1694179348491' className="d-block 300 imgsize inline_flex" alt="caru1" />
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
          </div>

         
        </div>

          {/* Carousel navigation buttons */}
          <div className="carousel_button_left_right">
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carousel;
