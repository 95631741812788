import { NavLink } from 'react-router-dom';
import './styles/card.css'



    
    
const Card = ({ imageSrc, description, title, price, image_title, duration, onCardClick }) => {

     const handleClick = () => {
    if (onCardClick) {
      onCardClick(imageSrc);
    }
  };

  return (
    <>
    <div className="card_body">
        <div className="card_flex">
            <div className="card_Left">
                <div className="cardisImg" onClick={handleClick}>
                    <img src={imageSrc} alt={image_title} width={100}/>
                    <div className="card-content">
                        <p className="card-title">{title}</p>
                        {/* <p className="card-description">{description}</p> */}
                        <p className="card-price">{price}</p>
                        {/* <p className="card-duration">{duration}</p> */}
                        <div className="book_appointment_button">
                            <NavLink to="/bookingSlip">
                                <button>Book Now</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </>
  );
};

export default Card;
