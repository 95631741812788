// CartContext.js
import { createContext, useState } from "react";
import { getProductPrice } from './productsStore';

export const CartContext = createContext();

export function CartProvider({ children }) {
  const [cartProducts, setCartProducts] = useState([]);

  function getProductQuantity(id) {
    return cartProducts.find(product => product.id === id)?.quantity || 0;
  }

  function addOneToCart(id) {
    const existingProduct = cartProducts.find(product => product.id === id);

    if (!existingProduct) {
      setCartProducts([...cartProducts, { id, quantity: 1 }]);
    } else {
      setCartProducts(
        cartProducts.map(product =>
          product.id === id ? { ...product, quantity: product.quantity + 1 } : product
        )
      );
    }
  }

  function removeOneFromCart(id) {
    setCartProducts(
      cartProducts.map(product =>
        product.id === id && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  }

  function deleteFromCart(id) {
    setCartProducts(cartProducts.filter(product => product.id !== id));
  }

  function getTotalCost() {
    let totalCost = 0;
  
    cartProducts.forEach(cartItem => {
      const productPrice = getProductPrice(cartItem.id);
      // Check if productPrice is a valid number
      if (typeof productPrice === 'number' && !isNaN(productPrice)) {
        totalCost += productPrice * cartItem.quantity;
      }
      // You may want to log an error or handle the case where getProductPrice returns undefined
    });
  
    return totalCost;
  }
  


  const contextValue = {
    items: cartProducts,
    getProductQuantity,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getTotalCost,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}


export default CartProvider;
