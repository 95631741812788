import Modal from 'react-modal';
import "./pageStyles/PortfolioSection.css"
import NavBar from "../component/NavBar1"
import Card from '../component/Card';
import Footer from '../component/Footer';
import React, { useState } from 'react';

import ReferredUserLogin from "../component/ReferredUserLogin"










const NailSection = () => {

  const [openRefer, setOpenRefer] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };



  const handleOpenRefer = (
    
    ) =>{
    
        setOpenRefer(!openRefer)
  
    }


  return (
<>
    <div className="porfolio_header">

      <NavBar />
      <div className="portfolio-section">
        <h5 className='head_text'>We offer a range of exeptional services tailored to meet your grooming needs.
          below are the service details including description, 
          pricing and duration for each of our services.</h5>
          { openRefer && (<div className="referal_login_absolute">
            <ReferredUserLogin />
          </div>) }
      <div className="card_heading">
        <div className="main_card_flex">
          <div className="card_left">
              {/* <Card
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail2.jpg?updatedAt=1694431420367" 
                title="90s Haircut"
                description="Recall the iconic flat top haircut that gained popularity in the 1990s?"
                duration="Duration: 50 minutes"
                price="Price: #4000"   
                onCardClick={openModal}
              /> */}
              {/* <Card
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail3.jpg?updatedAt=1694431420342" 
                title="Mohawk Dreadlocks"
                description="For a unique twist on dreads, try a mohawk—a 
                captivating choice to stand out distinctively."
                duration="Duration: 50 minutes"
                price="Price: #3000"          
                onCardClick={openModal}
              />
              <Card
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail1.jpg?updatedAt=1694431420250" 
                title="Short Afro with Temple Fade"
                description="Discover an amazing low skin fade, complemented by a 
                sculpted afro top and sharp sides."
                duration="Duration: 50 minutes"
                price="Price: #3000"       
                onCardClick={openModal}
              />
              <Card
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail4.jpg?updatedAt=1694431420220" 
                title="Short, Edgy and Wavy"
                description="Short hair gains allure with trendy cut, contrasting lines, 
                and enhancing natural texture for black men.."
                duration="Duration: 50 minutes"
                price="Price: #3000"       
                onCardClick={openModal}
              />
              <Card
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail5.jpg?updatedAt=1694431420150" 
                title="Male African Temple Fade"
                description="Fades, a classic choice for men, offer a clean, sophisticated look, 
                ideal for busy schedules."
                duration="Duration: 40 minutes"
                price="Price: #2000"     
                onCardClick={openModal}
              />*/}
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nail%2017k.jpg?updatedAt=1708441026495"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails8%2017k.jpg?updatedAt=1708440959598"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails5%2015k.jpg?updatedAt=1708440959507"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails7%2026k.jpg?updatedAt=1708440959496"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #26000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails4%2026k.jpg?updatedAt=1708440956100"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #26000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails3%2023k.jpg?updatedAt=1708440953966"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #23000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Water%20Jail%20Acrylic%20nails%2020k.jpg?updatedAt=1708440942326"
                  title="Water Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #20000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal2%20jails%20both%2015k.jpg?updatedAt=1708440942283"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal1%20jail%20nails%208k.jpg?updatedAt=1708440942259"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #8000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20white%20tips%206k.jpg?updatedAt=1708440942229"
                  title="Normal White Tips Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #6000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail2%209k.jpg?updatedAt=1708440942134"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #9000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%20nails%208k.jpg?updatedAt=1708440942053"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #9000"
                onCardClick={openModal}
                /> 
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails11%2017k.jpg?updatedAt=1708440928329"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                /> 
           
              {/* <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails1%2017k.jpg?updatedAt=1708440940081"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%20nails%207k.jpg?updatedAt=1708440937708"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #7000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails13%2010k.jpg?updatedAt=1708440928594"
                  title="Acrylic with stones"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #25000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%208k.jpg?updatedAt=1708440928529"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #8000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails14%2018k.jpg?updatedAt=1708440928534"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #14000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails15%2014k.jpg?updatedAt=1708440928529"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%2010k.jpg?updatedAt=1708440928488"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #10000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails10%20both%2018k.jpg?updatedAt=1708440928457"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #18000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails9%2015k.jpg?updatedAt=1708440928446"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails9%2015k.jpg?updatedAt=1708440928446"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #9000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails11%2017k.jpg?updatedAt=1708440928329"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                />  */}
             
              </div>
             <div className="card_Right">
             <div className="refer_button">
                  <button onClick={handleOpenRefer}>Refer a friend and get <span className='twenty'>20%</span>discount</button>
              </div>
                {/* <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/acrylic8.jpg?updatedAt=1695855711597"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000" 
                onCardClick={openModal}
                /> */}
                            <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails1%2017k.jpg?updatedAt=1708440940081"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #17000"
                onCardClick={openModal}
                /> 
                   <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails%2018k.jpg?updatedAt=1708440940441"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #18000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails2%20with%20stones%2025k.jpg?updatedAt=1708440940425"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #25000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%20nails%207k.jpg?updatedAt=1708440937708"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #7000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails13%2010k.jpg?updatedAt=1708440928594"
                  title="Acrylic with stones"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #25000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%208k.jpg?updatedAt=1708440928529"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #8000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails14%2018k.jpg?updatedAt=1708440928534"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #14000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails15%2014k.jpg?updatedAt=1708440928529"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Normal%20jail%2010k.jpg?updatedAt=1708440928488"
                  title="Normal Jail Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #10000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails10%20both%2018k.jpg?updatedAt=1708440928457"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #18000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails9%2015k.jpg?updatedAt=1708440928446"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #15000"
                onCardClick={openModal}
                /> 
              <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Richard%20Nails/Acrylic%20nails9%2015k.jpg?updatedAt=1708440928446"
                  title="Acrylic Nails"
                  description="Since 1960, natural afros gained popularity, embraced by icons, evolving with modern fades."
                  duration="Duration: 50 minutes"
                  price="Price: #9000"
                onCardClick={openModal}
                /> 
                <div className="refer_button">
                  <button onClick={handleOpenRefer}>Refer a friend and get <span className='twenty'>20%</span>discount</button>
                </div>
        
                {/* <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/acrylic3.jpg?updatedAt=1695855711457"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                onCardClick={openModal}
                />
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/acrylic5.jpg?updatedAt=1695855711449"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                onCardClick={openModal}
                />
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/acrylic4.jpg?updatedAt=1695855711280"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                onCardClick={openModal}
                />
                <Card
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/acrylic1.jpg?updatedAt=1695855711232"
                  title="Colored Mohawk"
                  description="Bleached twisted curls highlight unique mohawk fade, enhancing textured coils for bold style,
                   especially on young African men."
                  duration="Duration: 50 minutes"
                  price="Price: #3000"
                onCardClick={openModal}
                /> */}
            </div>
        </div>

      </div>
      <div className="take_note">
          <h3>Notice!!!</h3>
            <p>Note that the pricing and duration mentioned above 
            are subject to change. Our Barber take pride in their craftmanship 
            and ensured each service is delivered with attention to detail and precision. 
            Visit us today to experience the art of grooming at its finest.</p>
          </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        // overlayClassName="custom-overlay" 
        contentLabel="Full Image Modal"
        style={{
          overlay: {
            backgroundColor: '#0b0d1c',
          },
          content: {
            maxWidth: '800px', 
            margin: 'auto',
           height: '483px',
           background: '#0b0d1c',
           inset: '18px',
           
          },
        }}
      >
        <div className="z-index_modal">
          <button className="close_barber_modal" onClick={closeModal}>&lt;</button>
          <img
            src={selectedImage}
            alt="Full Image"
            style={{
              width: '100%',
              height: '70%',
              display: 'block',
              margin: '0 auto',
              position: 'relative',
              top: '52px',
             

            }}
          />
          <div className="book_appointment_button modal_absolut">
                  <button>Book Now</button>
          </div>
        </div>
      </Modal>
      </div>
      <div className="barber_store_footer">
        <Footer />
      </div>
    </div>
      {/* <Background /> */}
     
</>
  )
        }


        export default NailSection;