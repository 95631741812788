import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { CartContext } from "../CartContext";
import { getProductData } from '../productsStore';

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);
  
    return (
      <>
        {productData ? (
          <>
            <h3>{productData.title}</h3>
            <p>{quantity} Selected</p>
            <p>${(quantity * productData.price).toFixed(2)}</p>
            <Button size="sm" onClick={() => cart.deleteFromCart(id)}>
              Remove
            </Button>
            <hr></hr>
          </>
        ) : (
          <p>Error: Product data not found</p>
        )}
      </>
    );
  }
  
  export default CartProduct;
