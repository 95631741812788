import React from 'react';
// import { NavLink } from 'react-router-dom';
import NavBar1 from "../component/NavBar1";


function Maintain() {


  return (
<div className="maintan_header">
<NavBar1 />
 <div className="full-screen">
      
  </div>
</div>

 
   
  )
}

export default Maintain