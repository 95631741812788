import React from 'react'
import './styles/footer.css'
import address from './images/address.png'
import whatsapp from './images/whatsapp.png'
import call from './images/call.svg'
import gmail from './images/gmail.svg'
import facebook from './images/facebook.png'
import twitter from './images/twitter.png'
import instagram from './images/instagram.png'
import tiktok1 from './images/tiktok1.png'
import { Link } from 'react-router-dom';



export default function Footer() {
  return (
    <div className='footer_header'>
      <div className="footer_center">

        <div className="footer_address_header">
          <h3 style={{textAlign: 'center', marginTop: '30px', marginBottom: '20px', color: '#dcb786'}}>FIND US HERE</h3>
          {/* <div className="footer_address">
            <div className="footer_img">
              <img src={address} alt="address" />
            </div>
            <p>No: 32, Adetayo Osho Street, Fola Agoro Roundabout, Shomolu. Lagos</p>
          </div> */}
          <div className="footer_address">
            <div className="footer_img">
              <img src={whatsapp} alt="whatsapp" width={15}/>
            </div>
            <p>08140307575</p>
          </div>
          <div className="footer_address">
          <div className="footer_img">
            <img src={call} alt="call" />
          </div>
            <p>09057749577</p>
          </div>
          <div className="footer_address">
          <div className="footer_img">
            <img src={gmail} alt="gmail" />
          </div>
            <p>koksibeautystore@gmail.com</p>
          </div>
        </div>

        <div className="footer_info_career">
          <div className="footer_informate">
            <h4>INFORMATION</h4>
            <Link to='/aboutus'>
              About Us
            </Link>
            <Link to='/blog'>
              Our Blog
            </Link>
            <Link to='/maintain'>
              Pick up Or Delivery
            </Link>
            <Link to='/bookingform'>
              Send Us Email
            </Link>
          </div>
          <div className="footer_career">
            <h4>CAREERS PATHS</h4>
            <Link to='/maintain'>
              Available Vacancy
            </Link>
            <Link to='/maintain'>
              For Training
            </Link>
          </div>
        </div>

        <div className="footer_info_career">
          <div className="footer_informate">
            <h4>OUR SERVICE</h4>
            <Link to='/barbingsection'>
              Barbing
            </Link>
            <Link to='/nailsection'>
              Fixing of Nails
            </Link>
            <Link to='/bookingslip'>
              Manicure / Pedicure
            </Link>
            <Link to='/bookingslip'>
              MakeUp
            </Link>
            <Link to='/barberstore'>
             Accessories Store
            </Link>
            <Link to='/barberstore'>
             Barbing Accessories Store
            </Link>
            <Link to='/bookingslip'>
             Piercing of Ear
            </Link>
          </div>
          <div className="footer_career">
            <h4>MY ACCOUNT</h4>
            <Link to='/maintain'>
              My Account
            </Link>
            <Link to='/maintain'>
              CheckOut
            </Link>
            <Link to='/maintain'>
              Add To Cart
            </Link>
          </div>
        </div>

        <div style={{textAlign: 'center', marginTop: '30px'}} className="newsleter_footer">
          <h3>NEWSLETTER</h3>
          <button className='newslet_button'>
            <Link to='/NewsletterSignup'>Subscribe</Link>
          </button>
          <p style={{marginTop: '20px'}}>to our mailing list to get new update from us <span><br /> <br /> OR <br /><br /></span></p>
          <div className="social_media_icon">
            <div className="facebook">
            <Link to='/maintain'>
              <img src={facebook} alt="facebook" width={20}/>
            </Link>
            </div>
            {/* <div className="facebook">
            <Link to='/navbar'>
            <img src={twitter} alt="twitter" width={20}/>
            </Link>
            </div> */}
            <div className="facebook">
            <Link to='https://instagram.com/koksibeautystore?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr'>
            <img src={instagram} alt="instagram" width={20}/>
            </Link>
            </div>
            <div className="facebook">
            <Link to='https://wa.me/message/3MDDAXHRXICTP1'>
            <img src={whatsapp} alt="whatsapp" width={20}/>
            </Link>
            </div>
            <div className="facebook">
            <Link to='https://www.tiktok.com/@koksibeautystore.com?_t=8hZVm4RL7OH&_r=1'>
            <img src={tiktok1} alt="tiktok1" width={20}/>
            </Link>
            </div>
            {/* <div className="facebook">
            </div>
            <div className="facebook">
            </div>
            <div className="facebook">
            </div> */}
            {/* <div className="facebook">
              <img src={tiktok} alt="tiktok" />
            </div> */}
          </div>
        </div>
       
      </div>
        <h5 style={{fontSize: '10px', marginTop: '20px'}}>Developed By Koksi Bassey</h5>
    </div>
  )
}
