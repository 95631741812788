import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'


const images = [
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/flat_top1.jpg?updatedAt=1694352196685',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/mohawk_dreadlocks.jpg?updatedAt=1694352196592',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Crimson.jpg?updatedAt=1698846073833",
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/man_bun.jpg?updatedAt=1694352196744',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/lawn%20green.jpg?updatedAt=1698846110288",
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/short_afro.jpg?updatedAt=1694352197141',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/light%20pink.jpg?updatedAt=1698846111781",
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/maroon.jpg?updatedAt=1698846110470",
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/wave_design.jpg?updatedAt=1694352197145',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/high_top.jpg?updatedAt=1694352197306',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Rust.jpg?updatedAt=1698846110306",
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Fuchsia.jpg?updatedAt=1698846110501",
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/twisted.jpg?updatedAt=1694352197352',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/burst.jpg?updatedAt=1694352256030',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/short_wavy.jpg?updatedAt=1694352197340',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/deep%20pink.jpg?updatedAt=1698846074029",
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/flat_top2.jpg?updatedAt=1694352256167',
  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/color_mohawk.jpg?updatedAt=1694352256212',
  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Gold.jpg?updatedAt=1698857232511",


];

const Barber = () => {
  const [currentImage, setCurrentImage] = useState(images[0]);



  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * images.length);
      setCurrentImage(images[randomIndex]);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='random_image barberImage'>
      <h3>HAIRCUT</h3>
      <NavLink to='/barbingSection'>
        <img
          className='rusty-border'
          src={currentImage}
          alt="Random"
          width={300}
          height={300}
          loading='lazy'
        />
      </NavLink>
    </div>
  );
};

export default Barber;