import React, { useState, useEffect } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL, // Import getDownloadURL
} from "firebase/storage";

function CreatePost({ isAuth }) {
  const [title, setTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [image, setImage] = useState(null);

  const postsCollectionRef = collection(db, "posts");
  let navigate = useNavigate();

  const createPost = async () => {
    if (image) {
      const storage = getStorage();
      const imageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(imageRef, image);

      const imageUrl = await getDownloadURL(imageRef); // Use getDownloadURL

      await addDoc(postsCollectionRef, {
        title,
        postText,
        imageUrl,
        author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
      });
    } else {
      await addDoc(postsCollectionRef, {
        title,
        postText,
        author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
      });
    }

    navigate("/");
  }

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  }

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, [isAuth, navigate]);

  return (
    <div className="createPostPage">
      <div className="cpContainer">
      <div  onClick={() => navigate(-1)} className="cancel_button_create">&lt;</div>
        <h1>Create A Post</h1>
        <div className="inputGp">
          <label className="creatpost_text">Write Your Topic:</label>
          <input style={{background: '#dee2e6cc', color: 'white'}}
            placeholder="Topic..."
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </div>
        <div className="inputGp">
          <label className="creatpost_text">Write Your Post:</label>
          <textarea
            style={{ background: '#dee2e6cc', color: 'white', wordWrap: 'break-word' }}
            placeholder="Post..."
            onChange={(event) => {
              setPostText(event.target.value);
            }}
          />
        </div>

        <div className="inputGp">
          <label className="creatpost_text">Upload Your Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        <button style={{background: '#0a58ca', color: '#f8f9fa'}} onClick={createPost}>Submit Post</button>
      </div>
    </div>
  );
}

export default CreatePost;
