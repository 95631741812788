import React from 'react'
import BarberCard from "./BarberCard"
import { NavLink } from 'react-router-dom';


function Barber_Accessories() {


    
  return (

    <div className="card_heading">
        <div className="main_card_flex">
          <div className="card_left">
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper16.jpg?updatedAt=1694184243420" 
                title="Stainless steel T-style wide blades"
                description=" Stainless steel T-style wide blades
				about 0.1mm gapped.
				•All metal housing.
				•Motor speed: : 6000RPM.
				•Lithium battery: 1400mAh,
				2hours full charge for 180 mins use.
				•NG-2021: With LED power indicator light.
				•NG-2021B: With LED remaining battery
				power display.
				•Accessories: 3 guide combs(2/3/4 mm),
				lubricating oil, cleaning brush,adapter
				and USB cable.
				•GIFT BOX
				QTY: 24 PCS/CTN
				CBM: 0.054 M³/CTN"
                // duration="Duration: 50 minutes"
                price="Price: #20,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper1.jpg?updatedAt=1694184328038" 
                title="High quality, precision 440C (9CR18)stainless steel taper blade"
                description="High quality, precision 440C (9CR18)stainless steel taper blade,can be adjusted to zero-overlap. Motor speed: 6500RPM. Lithium battery: 2500mAh. 2-3 hours full charge for 240 mins use. With LED battery power display. Iron manRed,golden and black color for choice. Accessories: 6 magnetic guide combs (1.5/3/4.5/6/10/13 mm), cleaning brush,charge base and adapter."
                // duration="Duration: 50 minutes"
                price="Price: #25,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/wooden_brush.jpg?updatedAt=1694179999183" 
                title="Wooden handle"
                description="Wooden handle. ●Nylon brist."
                // duration="Duration: 50 minutes"
                price="Price: #1,500"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper17.jpg?updatedAt=1694179357288" 
                title="RECHARGEABLE BALDING CLIPPER"
                description=" About 0.1mm gapped T-style wide blade. •The clipper head can rotate in 2 directions. •NI-CD batteries: 2x600mAh. •8 hours full charge for 90 mins use.•Accessories: 4 guide combs (3/6/9/12mm), cleaning brush, lubricating oil and adapter. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.060 M³/CTN "
                // duration="Duration: 50 minutes"
                price="Price: #10,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper2.jpg?updatedAt=1694179357021" 
                title="Stainless steel T-cutter blades"
                description=" Stainless steel T-cutter blades about 0.1mm gapped. •Motor speed: 6500RPM. •Lithium battery: 1500mAh, 2.5 hours full charge for 180 mins use. •With LED remaining battery power and motor speed display. •Accessories: 3 guide combs(1/2/3mm), cleaning brush charge stand and adapter. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.051 M³/CTN  "
                // duration="Duration: 50 minutes"
                price="Price: #24,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper3.jpg?updatedAt=1694179352430" 
                title="WMACK Clipper"
                description="Stainless steel T-cutter blades about 0.1mm gapped. •Motor speed: 6000RPM. •Lithium battery: 2000mAh, 2hours full charge for 180 mins use. •With LED remaining battery power display. •With a charging base for choice. •Accessories: 4 guide combs (1.5/3/4.5/6 mm),lubricating oil, cleaning brush and USB cable, charging stand for choice. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.054 M³/CTN"
                // duration="Duration: 50 minutes"
                price="Price: #20,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper13.jpg?updatedAt=1694179348590" 
                title="Stainless steel T-style wide blades"
                description="Stainless steel T-style wide blades about 0.1mm gapped. •Lithium battery: 1400mAh, 2.5 hours full charge for 180 mins use. •4 speed settings: 5500/6000/6500/7000RPM. •With LED remaining battery power and motor speed display. •Charged by USB cable or adaptor. •Accessories: 3 guide combs (1mm, 2mm 3mm),cleaning brush, lubricating oil, USB charging cable and adaptor. • GIFT BOX QTY: 24 PCS/CTN CBM: 0.053 M³/CTN"
                // duration="Duration: 50 minutes"
                price="Price: #17,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper6.jpg?updatedAt=1694179348556" 
                title=" High quality, precision 440C (9CR18) stainless blades"
                description=" High quality, precision 440C (9CR18) stainless blades, can be adjusted to zero-overlap. •All-metal housing. •Motor speed: 6500RPM. •Lithium battery: 2600 mAh, 3-4 hours full charge for about 240 mins use. •Four gear click-clack taper lever:0.5/1.5/2.5/3.5MM. •With LED remaining battery power and motor speed display. •Accessories: 6 guide combs(1.5/3/4.5/6/10/13mm), lubricating oil,cleaning brush and adapter. •GIFT BOX QTY: 12 PCS/CTN CBM: 0.034 M³/CTN"
                // duration="Duration: 50 minutes"
                price="Price: #21,000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper4.jpg?updatedAt=1694179348491" 
                title=" High quality, precision 440C (9CR18)stainless blades"
                description="High quality, precision 440C (9CR18)stainless blades, can be adjusted to zero-overlap. •All-metal housing. •Motor speed: 6000RPM. •Lithium battery: 2500 mAh, 3-4 hours full charge for about 240 mins use. •A: With LED power indicator light. B: With LED remaining battery power display. •Accessories: 4 guide combs (1.5/3/4.5/6mm), lubricating oil, cleaning brush and adapter. •GIFT BOX QTY: 12 PCS/CTN CBM: 0.042 M³/CTN"
                // duration="Duration: 50 minutes"
                price="Price: #3000"             
              />
              <BarberCard
                imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper12.jpg?updatedAt=1694179348456" 
                title="KIKI Clipper"
                description="Voltage: 100V-240V, 50/60Hz, power:8W. •With battery capacity LCD display. •ABS housing + high carbon steel blade. •Perfect close-cutting, blade can be adjusted to zero-overlap. •Lithium battery:2000 mAh. •Support USB charge function. •4 hous full charge for 240 minitues use. •Accessories:4 guide combs(3/6/10/13mm), cleaning brush, lubrication oil, adapter or USB cable for choice."
                duration="Duration: 50 minutes"
                price="Price: #10,000"             
              />

              <div className="take_note">
                <p>Please note that the pricing and duration mentioned above 
                are subject to change. Our Barber take pride in their craftmanship 
                and ensured each service is delivered with attention to detail and precision. 
                Visit us today to experience the art of grooming at its finest</p>
              </div>
              </div>
             <div className="card_Right">
                <div className="refer_button">
                    <NavLink to="/maintain">
                        <button>Refer a friend and get <span className='twenty'>20%</span>discount</button>
                    </NavLink>
                </div>
                <BarberCard
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush3.jpg?updatedAt=1694179906292"
                  title="Plastic Hand Brush"
                  description="● Nylon bristle.
                  ● With powder spray function."
                  // duration="Duration: 50 minutes"
                  price="Price: #1,000"
                />
                <BarberCard
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush1.jpg?updatedAt=1694179906300"
                  title="Plastic Hand Brush"
                  description="•Plastic handle. •PP or PVC brush. •With powder spray function. •Product length: approx 16.7cm."
                  // duration="Duration: 50 minutes"
                  price="Price: #1,300"
                />
                <BarberCard
                  imageSrc="https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush2.jpg?updatedAt=1694179906247"
                  title="Plastic Hand Brush"
                  description="●Plastic handle ●Nylon brist"
                  // duration="Duration: 50 minutes"
                  price="Price: #1,900"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/neck_paper.jpg?updatedAt=1694179906123'

                  title="Approx. 95-100g/roll"
                  description=" Approx. 95-100g/roll. •Black, red and blue colours for choice. •Product size: Φ10.3cm x height 6.5cm. •Protection against hair scrap. •200% paper stretching. •100% water resistance. •SHRINK FILM QTY: 5 PCS/BIG ROLL 100 PCS/CTN CBM: 0.083 M³/CTN"
                  // duration="Duration: 50 minutes"
                  price="Price: #3000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR.jpg?updatedAt=1694179905932'
                  title="RAZOR"
                  description="Material: stainless steel+plastic •Size: 12.5x2.5 cm •With blade inside."
                  // duration="Duration: 50 minutes"
                  price="Price: #2,000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush.jpg?updatedAt=1694179905892'
                  title="Wooden handle Powder Brush"
                  description="Wooden handle ●Nylon brist."
                  // duration="Duration: 50 minutes"
                  price="Price: #1,500"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/towel_warmer.jpg?updatedAt=1694179905668'
                  title="Towel Warmer"
                  description=" Power: 200W. •Volume: 23L. •Two racks included. •With 1x8W UV lamp and fuse. •Constant temperature: ±70℃. •On/off switch with power indicator light. •Product dimensions: 45x30x35cm. •Power cord 1.5m"
                  // duration="Duration: 50 minutes"
                  price="Price: #75,000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/CURL%20SPONGE.jpg?updatedAt=1694179648800'
                  title="CURL SPONGE"
                  description="Material:EVA+high density sponge. •For curling use. •Size: Approx 19x11x6cm."
                  // duration="Duration: 50 minutes"
                  price="Price: #3000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper14.jpg?updatedAt=1694179358164'
                  title="Stainless steel T-cutter blades"
                  description="Stainless steel T-cutter blades, about 0.1mm gapped. •Zinc alloy housing. •Motor speed: 6500RPM. •Lithium battery: 800mAh, 2hours full charge for 120 mins use. •With LED remaining battery power display. •USB charging, Type C charging interface. •Accessories: 3 guide combs (1/3/6mm), lubricating oil, cleaning brush and USB charging cable. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.034 M³/CTN"
                  // duration="Duration: 50 minutes"
                  price="Price: #15,000"
                />
              
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper15.jpg?updatedAt=1694179357716'
                  title="stainless steel T-cutter blade"
                  description="5CR13 stainless steel T-cutter blade. •All metal housing. •Motor speed: 7500RPM. •Lithium battery: 1400mAh, 2.5 hours full charge for 180 mins use. •With LED display for remaining working time. •USB charging, Type C charging interface. •Accessories: 3 guide combs (1/2/3mm), lubricating oil, cleaning brush and USB charging cable, adaptor for choice. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.035 M³/CTN"
                  // duration="Duration: 50 minutes"
                  price="Price: #20,000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/nose_clipper.jpg?updatedAt=1694179906377'
                  title="Nose & ear Hair Trimmer"
                  description="Nose & ear Hair Trimmer •2 in 1 set:nose/ear hair trimmer and sideburn trimmer. •Rotation Speed:9000RPM Nose & ear Hair Trimmer •2 in 1 set:nose/ear hair trimmer and sideburn trimmer. •Rotation Speed:9000RPM "
                  // duration="Duration: 50 minutes"
                  price="Price: #5000"
                />
                <BarberCard
                  imageSrc='https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper18.jpg?updatedAt=1694180078668'
                  title="Reachargeable Balding Clipper"
                  description="•Stainless steel blade •NI-CD batteries: 2x600mAH. •8 hours full charge for 90 mins use. •Accessories: 4 guilde combs (3/6/9/12), cleaning brush, lubricating oil and adpter."
                  // duration="Duration: 50 minutes"
                  price="Price: #5000"
                />
            </div>
        </div>
    </div>
  )
}

export default Barber_Accessories;