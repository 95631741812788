// ProductCard.js

import React from 'react';
import { Card, Button, Form, Row, Col } from 'react-bootstrap';
import { CartContext } from '../CartContext';
import { useContext } from 'react';

const ProductCard = (props) => {
  const { product, getProductPrice } = props;
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id);

  return (
    <Card style={{ width: '18rem', height: '100%' }}>
      <Card.Img
        variant="top"
        src={product.ImageURL}
        alt={product.title}
        style={{ objectFit: 'cover', width: '100%', height: 'auto' }} // Adjust the height as needed
      />
      <Card.Body>
        <Card.Title>{product.title}</Card.Title>
        <Card.Text>#{getProductPrice(product.id)}</Card.Text>
        {productQuantity > 0 ? (
          <>
            <Form as={Row}>
              <Form.Label column="true" sm="6">
                In Cart: {productQuantity}
              </Form.Label>
              <Col sm="6">
                <Button sm="6" onClick={() => cart.addOneToCart(product.id)} className="mx-2">
                  +
                </Button>
                <Button sm="6" onClick={() => cart.removeOneFromCart(product.id)} className="mx-2">
                  -
                </Button>
              </Col>
            </Form>
            <Button variant="danger" onClick={() => cart.deleteFromCart(product.id)} className="my-2">
              Remove from cart
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={() => cart.addOneToCart(product.id)}>
            Add To Cart
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
