import React, { Component } from "react";
import Barber from './Barber';
import { NavLink } from 'react-router-dom';
import Nail from './Nail';
import openLine from '../component/images/openLine.png';
import AboutUs from './AboutUs';
import NavBar1 from "./NavBar1";
import Background from './Background';
import Carousel from './Carousel';
import BarbingTools from './BarbingTools';
import Footer from './Footer';
import HomeFunctional from './HomeFunctional';

export class Home extends Component {
  state = {
    showWelcome: false,
  };

  
  
  componentDidMount() {
    (function(d, m) {
      var kommunicateSettings =
        {"appId": "3e06bc8367ca82676b3d199dcfdbfa60d", "popupWidget": true, "automaticChatOpenOnNavigation": true};
      var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      var h = document.getElementsByTagName("head")[0]; h.appendChild(s);
      window.kommunicate = m; m._globals = kommunicateSettings;
    })(document, window.kommunicate || {});

 
  }

  // style={{
  //   width: '100%',
  //   height: '70%',
  //   display: 'block',
  //   margin: '0 auto',
  //   position: 'relative',
  //   top: '52px',
  // }}


//   content: {
//     maxWidth: '800px', 
//     margin: 'auto',
//    height: '483px',
//    inset: '18px',
//   },
// }}

  render() {
    const { isAuth, userName } = this.props;
    const { showWelcome } = this.state;
  
    return (
      <>
        <NavBar1 />
        <div className="all_sellect">
          <div className="home-header">
            <h4>
              Embrace confidence and styles at 
              <span className='deesqure_collect'> Koksi Beauty Store!! </span> 
              Redefining your image, Transform your looks and your life.
              Koksi Beauty Store makes it possible.
            </h4>
      
            <div className="homefunction">
            <HomeFunctional isAuth={isAuth} userName={userName} onLogin={this.showWelcomeMessage} />
            </div>
          </div>
          <Carousel />
          <div className="card_appointment_button">
            <NavLink to="/bookingslip">
              <button>Book Appointment</button>
            </NavLink>
          </div>
          <div className='home-flex'>
            <BarbingTools />
            <Nail />
            <Barber />
          </div>
          <div className="about_us_flex">
            <AboutUs />
            <div className="home-sevice">
              <h3 className='home_ser'>HOME SERVICE</h3>
              <NavLink to='/bookingslip'>
                <img className='home-sevice_img' src='https://ik.imagekit.io/eitvb0lyq/home-service.jpg?updatedAt=1696856160861' alt="home sevice" width={300} />
              </NavLink>
              <p>Experience the ultimate home grooming convenience with our skilled team of Barbers, Nails Technicians,
                and Stylists. At Koksi Beauty Store, we understand your unique needs, offering personalized services
                and flexible pricing, based on negotiations, to ensure your comfort. We view you as a valued partner
                in achieving your desired look and style. Contact us today to make your grooming experience convenient
                and tailored to your preferences.
              </p>
              <div className="opening_hour_home">
                <div className="opening_hour">
                  <h5>OPENING HOURS</h5>
              <img className='opening_hour_line' src={openLine} alt="openLine" />
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Monday</p>
                </div>
                <div className="monday_time">
                  <p>9am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>6:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Tuesday</p>
                </div>
                <div className="monday_time">
                  <p>10am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>6:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Wednesday</p>
                </div>
                <div className="monday_time">
                  <p>10am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>6:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Thursday</p>
                </div>
                <div className="monday_time">
                  <p>10am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>6:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Friday</p>
                </div>
                <div className="monday_time">
                  <p>9am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>7:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Saturday</p>
                </div>
                <div className="monday_time">
                  <p>8am <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>7:30pm</p>
                </div>
              </div>        
              <div className="monday_flex">
                <div className="monday_date">
                  <p>Sunday</p>
                </div>
                <div className="monday_time">
                  <p>12pm <span className='dash_line'>-</span></p>
                </div>
                <div className="monday_time">
                  <p>6:30pm</p>
                </div>
              </div>
              </div>
              </div>
            </div> 
         </div>
          <Footer />        
        </div>  
        <Background />
          </>
        
        )
    }
}


export default Home;