import { useState } from 'react';
import { push, ref, set, getDatabase } from 'firebase/database';
import { NavLink } from 'react-router-dom';
import { app } from '../firebaseConfig'; // Import the Firebase app
import './Book.css';


const Book = () => {
    const [success, setSuccess] = useState(false)
    let BookUs = [
            {
                name: "Hair Cut",
                // price: "3000",
                image: "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/flat_top1.jpg?updatedAt=1694352196685",
            },
    
            {
                name: "Fixing of Nails",
                // price: "5000",
                image: "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/Nails%20library/nail5.jpg?updatedAt=1694431420150",
            },
            {
                name: "MakeUp",
                // price: "10000",
                image: "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/MakeUp/makeup3.jpg?updatedAt=1694433335240",
            },
            {
                name: "Home Service",
                // price: "15000",
                image: "https://ik.imagekit.io/eitvb0lyq/home-service.jpg?updatedAt=1696856160861",
            },
            {
                name: "PIERCING",
                // price: "15000",
                image: "https://ik.imagekit.io/eitvb0lyq/PIRCING.jpg?updatedAt=1700647607271",
            },
            {
                name: "PEDICURE",
                // price: "15000",
                image: "https://ik.imagekit.io/eitvb0lyq/MANICURE(1).jpg?updatedAt=1700647642929",
            },
            {
                name: "MANICURE",
                // price: "15000",
                image: "https://ik.imagekit.io/eitvb0lyq/MANICURE.jpg?updatedAt=1700647607736",
            }
        ]

  const bookHotel = (index) => {
    const imageTarget = document.querySelector("#targetImage");
    const nameTarget = document.querySelector("#targetName");
    // const priceTarget = document.querySelector("#targetPrice");
    const cards = document.querySelector(".cards_box");
    const request = document.querySelector(".request");

    imageTarget.src = BookUs[index].image;
    nameTarget.innerHTML = BookUs[index].name;
    // priceTarget.innerHTML = '#' + BookUs[index].price;
    cards.style.display = "none";
    request.style.display = "block";

    // Write appointment data to Firebase
    writeUserData(index);
  }

  const writeUserData = (index) => {
    const db = getDatabase(app); //
    // const db = getDatabase();
    const appointmentsRef = ref(db, 'appointments');

    // Generate a unique key for the new appointment
    const newAppointmentRef = push(appointmentsRef);

    const appointmentData = {
      Image: BookUs[index].image,
      Name: BookUs[index].name,
      Price: '#' + BookUs[index].price,
    };

    // Set the data under the unique key
    set(newAppointmentRef, appointmentData);
    
  }

  const request = () => {
    const imageTarget = document.querySelector("#targetImage");
    const nameTarget = document.querySelector("#targetName");
    const priceTarget = document.querySelector("#targetPrice");
    const request = document.querySelector(".request");
    const cards = document.querySelector(".cards_box");
    setSuccess(true)

    request.style.display = "none";
    cards.style.display = "block";

    imageTarget.src = '';
    nameTarget.innerHTML = '';
    priceTarget.innerHTML = '';
  }

  return (
    <>
        {success && ( 
        <div className="book_success">
                 <h2>Your request has been sent</h2>
                <div className="ok_button">
                   <NavLink to="/">
                    <button>Ok</button>
                   </NavLink>
                </div>
               </div>
               )} 
     
      <div className="box">
        <div className="content">
          <div className="text">Choose what Service you want</div>
          <div className="target">
            <div className="trgt">
              <div className="card">
                <img src="" id='targetImage' alt="" />
                <div className="hotel_name" id='targetName'></div>
                <div className="price" id='targetPrice'></div>
              </div>
            </div>
          </div>
          <div className="cards">
            <div className="cards_box">
              {BookUs.map((hotel, index) => (
                <div className="card" key={index} onClick={() => bookHotel(index)}>
                  <img src={hotel.image} alt={`hotel${index + 1}`} />
                  <div className="hotel_name">{hotel.name}</div>
                  {/* <div className="price">{'#' + hotel.price}</div> */}
                </div>
              ))}
            </div>
            <div className="request">
              <button className="btn" onClick={request}>Request</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Book;



