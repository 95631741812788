import React from 'react';
import './styles/homeservice.css'

const HomeService = () => {
  return (
    <div className="about-us-container">
      <h2>Welcome to Your Ultimate Grooming and Beauty Experience at Home</h2>
      <p>
        At [Deesquare], we understand that life can get busy, and finding time for self-care can be a challenge. That's why we bring the salon and barbershop experience right to your doorstep.
      </p>

      <div className="feature">
        <img src="/path-to-mobile-barbing-image.jpg" alt="Mobile Barbing" />
        <h3>Mobile Barbing</h3>
        <p>Our expert barbers are just a call away. Get a fresh haircut, a classic shave, or even a trendy beard styling, all in the comfort of your home.</p>
      </div>

      <div className="feature">
        <img src="/path-to-nail-fixing-image.jpg" alt="Nail Fixing and Manicures" />
        <h3>Nail Fixing and Manicures</h3>
        <p>Pamper your hands and feet with our nail fixing and manicure services. Choose from a range of nail designs and treatments to suit your style.</p>
      </div>

      <div className="feature">
        <img src="/path-to-hair-styling-image.jpg" alt="Hair Styling" />
        <h3>Hair Styling</h3>
        <p>Need a new hairstyle? Our hairstylists are equipped to provide cuts, styling, and hair treatments for all hair types.</p>
      </div>

      <div className="feature">
        <img src="/path-to-makeup-and-beauty-image.jpg" alt="Makeup and Beauty" />
        <h3>Makeup and Beauty</h3>
        <p>Get ready for that special occasion with our makeup and beauty services. Whether it's a wedding, party, or photoshoot, we've got you covered.</p>
      </div>

      {/* Include the rest of the content and features as needed */}

      <p>
        Transform your grooming and beauty routine with [Deesquare]. Book your appointment today and experience the luxury of professional grooming and nail fixing services at your doorstep.
      </p>
    </div>
  );
};

export default HomeService;
