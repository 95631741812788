// import React from 'react';
import React, { useState} from "react";
import { NavLink, Link } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './styles/SideMenu.css'; // Import your CSS file
// import { signOut } from "firebase/auth";
// import { auth } from "../firebaseConfig";



const SideMenu = () => {


  return (
    <div className="side-menu">
      <div className="service-flex">
        <h2>OUR SERVICES</h2>
      </div>
        <NavLink to="/barbingsection" activeclassName="active">HAIRCUT</NavLink>
        <NavLink to="/bookingslip" activeclassName="active">MANICURE / PEDICURE</NavLink>
        <Link to="/store">SALES OF ACCESSORIES</Link>
        <NavLink 
        to="/NailSection" 
        activeclassName="active">FIXING OF NAILS</NavLink>
        <NavLink 
        to="/bookingslip" 
        activeclassName="active">PIERCING</NavLink>
        <NavLink to="/bookingslip" activeclassName="active">HOME SERVICES</NavLink>
        <NavLink to="/blog" activeclassName="active">OUR BLOG</NavLink>

    </div>
  );
};

export default SideMenu;
