import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getProductPrice } from '../productsStore';
import ProductCard from '../component/ProductCard';
import NavBar1 from '../component/NavBar1';
import Footer from '../component/Footer';
// import Background from '../component/Background';


function Store() {

    const productsArray = [
        {
            id: "0",
            title: "Stainless steel T-style wide blades",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper16.jpg?updatedAt=1694184243420',
            description: 'Stainless steel T-style wide blade about 0.1mm gapped. •All metal housing. •Motor speed: : 6000RPM. •Lithium battery: 1400mAh, 2hours full charge for 180 mins use. •NG-2021: With LED power indicator light. •NG-2021B: With LED remaining battery power display. •Accessories: 3 guide combs(2/3/4 mm), lubricating oil, cleaning brush,adapterand USB cable. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.054 M³/CTN',
            price: 20000
        },
        {
            id: "1",
            title: "High quality, precision 440C (9CR18)stainless steel taper blade",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper1.jpg?updatedAt=1694184328038',
            description: "High quality, precision 440C (9CR18)stainless steel taper blade,can be adjusted to zero-overlap. Motor speed: 6500RPM. Lithium battery: 2500mAh. 2-3 hours full charge for 240 mins use. With LED battery power display. Iron manRed,golden and black color for choice. Accessories: 6 magnetic guide combs (1.5/3/4.5/6/10/13 mm), cleaning brush,charge base and adapter.",
            price: 25000
        },
    
    
        {
            id: "3",
            title: "Wooden handle",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/wooden_brush.jpg?updatedAt=1694179999183',
            description: "Wooden handle. ●Nylon brist.",
            price: 1500
        },

        {
            id: "5",
            title: "Stainless steel T-cutter blades",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper2.jpg?updatedAt=1694179357021',
            description: " Stainless steel T-cutter blades about 0.1mm gapped. •Motor speed: 6500RPM. •Lithium battery: 1500mAh, 2.5 hours full charge for 180 mins use. •With LED remaining battery power and motor speed display. •Accessories: 3 guide combs(1/2/3mm), cleaning brush charge stand and adapter. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.051 M³/CTN  ",
            price: 24000
        },

        {
            id: "9",
            title: "High quality, precision 440C (9CR18) stainless blade",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper6.jpg?updatedAt=1694179348556',
            description: " High quality, precision 440C (9CR18) stainless blades, can be adjusted to zero-overlap. •All-metal housing. •Motor speed: 6500RPM. •Lithium battery: 2600 mAh, 3-4 hours full charge for about 240 mins use. •Four gear click-clack taper lever:0.5/1.5/2.5/3.5MM. •With LED remaining battery power and motor speed display. •Accessories: 6 guide combs(1.5/3/4.5/6/10/13mm), lubricating oil,cleaning brush and adapter. •GIFT BOX QTY: 12 PCS/CTN CBM: 0.034 M³/CTN",
            price: 21000
        },
        {
            id: "10",
            title: "High quality, precision 440C (9CR18)stainless blade",
            ImageURL:  "https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper4.jpg?updatedAt=1694179348491",
            description: "High quality, precision 440C (9CR18)stainless blades, can be adjusted to zero-overlap. •All-metal housing. •Motor speed: 6000RPM. •Lithium battery: 2500 mAh, 3-4 hours full charge for about 240 mins use. •A: With LED power indicator light. B: With LED remaining battery power display. •Accessories: 4 guide combs (1.5/3/4.5/6mm), lubricating oil, cleaning brush and adapter. •GIFT BOX QTY: 12 PCS/CTN CBM: 0.042 M³/CTN",
            price: 3000
        },
        {
            id: "12",
            title: "KIKI Clipper",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper12.jpg?updatedAt=1694179348456',
            description: "Voltage: 100V-240V, 50/60Hz, power:8W. •With battery capacity LCD display. •ABS housing + high carbon steel blade. •Perfect close-cutting, blade can be adjusted to zero-overlap. •Lithium battery:2000 mAh. •Support USB charge function. •4 hous full charge for 240 minitues use. •Accessories:4 guide combs(3/6/10/13mm), cleaning brush, lubrication oil, adapter or USB cable for choice.",
            price: 10000
        },
    
        {
            id: "14",
            title: "Plastic Hand Brush",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush1.jpg?updatedAt=1694179906300',
            description: "•Plastic handle. •PP or PVC brush. •With powder spray function. •Product length: approx 16.7cm.",
            price: 1500
        },

        {
            id: "16",
            title: "Plastic Hand Brush",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/power_brush2.jpg?updatedAt=1694179906247',
            description: "●Plastic handle ●Nylon brist",
            price: 2000
        },
        {
            id: "17",
            title: "Approx. 95-100g/roll",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/neck_paper.jpg?updatedAt=1694179906123',
            description: " Approx. 95-100g/roll. •Black, red and blue colours for choice. •Product size: Φ10.3cm x height 6.5cm. •Protection against hair scrap. •200% paper stretching. •100% water resistance. •SHRINK FILM QTY: 5 PCS/BIG ROLL 100 PCS/CTN CBM: 0.083 M³/CTN",
            price: 3000
        },
        
        {
            id: "18",
            title: "Razor Handle",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/RAZOR.jpg?updatedAt=1694179905932',
            description: "Material: stainless steel+plastic •Size: 12.5x2.5 cm •With blade inside.",
            price: 4000
        },
    
        {
            id: "18",
            title: "Towel Warmer",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/towel_warmer.jpg?updatedAt=1694179905668',
            description: " Power: 200W. •Volume: 23L. •Two racks included. •With 1x8W UV lamp and fuse. •Constant temperature: ±70℃. •On/off switch with power indicator light. •Product dimensions: 45x30x35cm. •Power cord 1.5m",
            price: 75000
        },

        {
            id: "20",
            title: "CURL SPONGE",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/CURL%20SPONGE.jpg?updatedAt=1694179648800',
            description: "Material:EVA+high density sponge. •For curling use. •Size: Approx 19x11x6cm.",
            price: 3000
        },
        {
            id: "21",
            title: "Stainless steel T-cutter blades",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper14.jpg?updatedAt=1694179358164',
            description: "Stainless steel T-cutter blades, about 0.1mm gapped. •Zinc alloy housing. •Motor speed: 6500RPM. •Lithium battery: 800mAh, 2hours full charge for 120 mins use. •With LED remaining battery power display. •USB charging, Type C charging interface. •Accessories: 3 guide combs (1/3/6mm), lubricating oil, cleaning brush and USB charging cable. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.034 M³/CTN",
            price: 15000
        },
        {
            id: "22",
            title: "stainless steel T-cutter blade",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper15.jpg?updatedAt=1694179357716',
            description: "5CR13 stainless steel T-cutter blade. •All metal housing. •Motor speed: 7500RPM. •Lithium battery: 1400mAh, 2.5 hours full charge for 180 mins use. •With LED display for remaining working time. •USB charging, Type C charging interface. •Accessories: 3 guide combs (1/2/3mm), lubricating oil, cleaning brush and USB charging cable, adaptor for choice. •GIFT BOX QTY: 24 PCS/CTN CBM: 0.035 M³/CTN",
            price: 20000
        },
        {
            id: "23",
            title: "Nose & ear Hair Trimmer",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/nose_clipper.jpg?updatedAt=1694179906377',
            description: "Nose & ear Hair Trimmer •2 in 1 set:nose/ear hair trimmer and sideburn trimmer. •Rotation Speed:9000RPM Nose & ear Hair Trimmer •2 in 1 set:nose/ear hair trimmer and sideburn trimmer. •Rotation Speed:9000RPM ",
            price: 5000
        },
        {
            id: "24",
            title: "Reachargeable Balding Clipper",
            ImageURL:  'https://ik.imagekit.io/eitvb0lyq/Barbers%20pictures/barber%20Accessories/clipper18.jpg?updatedAt=1694180078668',
            description: "•Stainless steel blade •NI-CD batteries: 2x600mAH. •8 hours full charge for 90 mins use. •Accessories: 4 guilde combs (3/6/9/12), cleaning brush, lubricating oil and adpter.",
            price: 5000
        },
 
     
      
    ];

    return (
        <>
        <NavBar1 />
        <div className="store_main_head">
          <div className="berber_store_hearder">
        <h1  style={{color: "#ffff", fontSize: "14px", marginTop: '20px'}} align="center" className="p-3">Welcome to <span style={{color: '#f39a0e'}}>Koksi Beauty Store,</span>  we are passionate about helping you achieve your best
                look and grooming experience. We offer a wide range of
                high-quality barbering products that cater to both 
                professionals and enthusiasts. Whether you're a seasoned 
                barber or just starting on your grooming journey, you'll find
                everything you need right here.
        </h1>
        <Row xs={1} md={4} className="g-4 product_card">
            {productsArray.map((product, idx) => (
                <Col align="center" key={idx}>
                    <ProductCard product={product} getProductPrice={getProductPrice} />
                </Col>
            ))}
        </Row>
      
        </div>
        <div className="barber_store_footer_store">
            <Footer />
        </div>
        </div>

    {/* <Background /> */}
    </>
    )
}

export default Store;