import React, { useEffect, useState } from 'react';
import { useFirebaseAuth } from '../FirebaseAuthContext/FirebaseAuthContext';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, auth } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import defaultAvatar from './images/avatar.jpg';
import './styles/Account.css'
import bell from './images/bell-line-icon.png'
import letter from './images/letter.png'
import location from './images/location.png'
import transaction from './images/icons8-transaction-24.png'
// import carticon from './images/carticon.svg';

// import { signOut } from 'firebase/auth';



const storage = getStorage();

const defaultAvatarURL = defaultAvatar;

const Account = () => {
  const { user } = useFirebaseAuth();
  const [points, setPoints] = useState(0);
  const [avatarURL, setAvatarURL] = useState(defaultAvatarURL);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [maintainance, setMaintainance] = useState(false);
  const [error, setError] = useState(null);



  const [isAuth, setIsAuth] = useState(localStorage.getItem('isAuth'));

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      // Fetch user's points and avatarURL from Firestore
      fetchUserData(user.uid);
    }
  }, [user]);

  const fetchUserData = async (userId) => {
    try {
      setLoading(true);
      const userDoc = await getDoc(doc(collection(db, 'users'), userId));

      if (userDoc.exists()) {
        setAvatarURL(userDoc.data().avatarURL || defaultAvatarURL);
        setPoints(userDoc.data().points || 0);
        setError(null);
      } else {
        setError('User document not found.');
      }
    } catch (error) {
      setError('Error fetching user data: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  // const cart = useContext(CartContext);

  // const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);



  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      navigate('/'); 
    });
  };


  const handleUpload = async () => {
    if (file) {
      try {
        console.log('Uploading file:', file);
        setLoading(true);
        // Create a reference to the storage location
        const storageRef = ref(storage, `avatars/${user.uid}/${file.name}`);

        // Upload file
        const uploadTask = await uploadBytes(storageRef, file);

        // Get the download URL for the uploaded file
        const url = await getDownloadURL(storageRef);

        // Update user document with the avatarURL
        await setDoc(doc(collection(db, 'users'), user.uid), {
          avatarURL: url,
        }, { merge: true });

        // Fetch updated avatarURL
        fetchUserData(user.uid);
        console.log('File uploaded successfully!');
      } catch (error) {
        setError('Error uploading file: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };




  const handleNotification = ( ) => {
    setMaintainance(true)

    setTimeout(() => {
      setMaintainance(false)
    }, 2000);
  }


  const handleLogout = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      navigate('/');
    });
  };

  const getDisplayLetter = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    } else {
      return '';
    }
  };


  

  return (
      <div className='welcome_profile_head'> 
        <div  onClick={() => navigate(-1)} className="cancel_button_profile">&lt;</div>
        {maintainance && (
            <div className="account_maintainance">
            <h4>This Feature is under development</h4>
            </div>
        )}
       
        <div className="welcome_profile_name">  
        <div>
          {getDisplayLetter() ? (
            <div
              className="profile-placeholder"
              style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                backgroundColor: '#495057',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '24px',
              }}
            >
              {getDisplayLetter()}
            </div>
          ) : (
            <img
              src={avatarURL || defaultAvatarURL}
              alt="Profile"
              style={{ maxWidth: '100px', borderRadius: '50%' }}
            />
          )}
        </div>
        <h2 className='welcome_profile'>{user && user.displayName ? user.displayName : 'User'}</h2>
        <p>{user && user.email}</p>
      </div>
      
        {/* <hr /> */}
        <div className="absolute_box">
          <div className="point_class1">
            <h5>Discount <span>{points}</span> </h5>
          </div>
          <div className="point_class2">
          <h5>My Referrals<span>0</span> </h5>
          </div>
          <div className="point_class3">
            <img src={bell} alt="bell"  width={16}/>
          <h5 onClick={handleNotification}>Notification</h5>
          </div>
        </div>
        {/* <input type="file" accept="image/*" onChange={handleFileChange} /> */}
        {/* <button onClick={handleUpload}>Upload Profile Photo</button> */}
        {uploadProgress !== null && <p>Progress: {uploadProgress.toFixed(2)}%</p>}
           <div className="white_background_account">
            <div className="accont_contents">
              <div className="transaction_details">
                <div className="transaction_img">
                <img src={transaction } alt="transaction" width={25} />
                </div>
                <div className="transaction_text" onClick={handleNotification}>
                <h2>Transactions</h2>
                </div>
                <span>&#62;</span>
              </div>
              <div className="transaction_details">
                <div className="transaction_img">
                <img src={letter} alt="letter" width={25} />
                </div>
                <div className="transaction_text">
                <Link to='/bookingform'>
                  <h2>Contact Us</h2>
                </Link>
                </div>
                <Link className='greater_link' to='/bookingform'>
                  <span>&#62;</span>
                </Link>

              </div>
              <div className="transaction_details">
                <div className="transaction_img">
                <img src={location } alt="location" width={25} />
                </div>
                <div className="transaction_text" onClick={handleNotification}>
                <h2>Locate Us</h2>
                </div>
                <span>&#62;</span>
              </div>
               {!isAuth ? (
              ""
          ) : (
        <div className="moblie_logout_button">
          <button onClick={signUserOut}> Log Out </button>
        </div>
          )}
            </div>
     
           </div>
          
      </div>
  );
};

export default Account;
