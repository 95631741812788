import { Link } from 'react-router-dom';


function Cancel() {
    return (
        
        <h1 className="success_puchase">Sorry to see you cancelled your Stripe payment!
        <button className='cancel_button'>
            <Link to='/'>
            Back to Home
            </Link>
            </button></h1>
    )
}

export default Cancel;