import { Button, Modal } from 'react-bootstrap';
import { useState, useContext, useRef, useEffect } from 'react';
import { CartContext } from '../CartContext';
import CartProduct from './CartProduct';
import { checkout } from './checkoutService';
import carticon from './images/carticon.svg';

import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { app } from '../firebaseConfig';
import { FaCopy } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

import Transaction from './Transaction';



// Add the following function for reading files asynchronously
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

const uploadAndRetrieveDownloadURL = async (dataURL) => {
  const storage = getStorage(app);
  const storageRef = ref(storage, 'transaction_images/' + Date.now());
  await uploadString(storageRef, dataURL, 'data_url');
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};

const saveUserData = async (downloadURL, cartItems) => {
  try {
    const db = getFirestore(app);
    const userCollection = collection(db, 'users');

    const userId = 'userId'; // You may use the user's ID or any unique identifier
    const userDocRef = await addDoc(userCollection, {
      userId: userId,
      downloadURL: downloadURL,
      cartItems: cartItems,
    });

    console.log('Document written with ID: ', userDocRef.id);

    return userDocRef;
  } catch (error) {
    console.error('Error saving user data:', error);
    throw error; // Rethrow the error for handling in the calling function
  }
};





function NavbarComponent() {
  const [successfully, setSuccessfully] = useState(false);
  const [userInfo, setUserInfo] = useState({
    fullName: '',
    emailAddress: '',
    homeAddress: '',
    phoneNumber: '',
  });
  const [transactionDetails, setTransactionDetails] = useState({});
  const cart = useContext(CartContext);


  


  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevUserInfo) => ({ ...prevUserInfo, [name]: value }));
  };

  const handleCopyClick = (e) => {
    e.stopPropagation();
    const inputElement = e.target.previousSibling;
    inputElement.select();
    document.execCommand('copy');
    alert('Account number copied to clipboard!');
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const performCheckout = async () => {
    const totalAmountInKobo = cart.getTotalCost();
    console.log('Total Amount Sent to Backend:', totalAmountInKobo);
    await checkout(cart.items, totalAmountInKobo);
  };

  const handleShow = () => {
    console.log('Opening modal');
    setShow(true);
  };

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  const [imageFile, setImageFile] = useState(null);
  const modalRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const [error, setError] = useState(null);

  const handleShareTransactionDetails = async () => {
    if (!imageFile || !userInfo.emailAddress.trim()) {
      alert('Please upload your screenshot receipt and provide a valid email address.');
      return;
    }
  
    try {
      const dataURL = await readFileAsync(imageFile);
      const downloadURL = await uploadAndRetrieveDownloadURL(dataURL);
  
      // Save user data including cart items
      const userDocRef = await saveUserData(downloadURL, cart.items);
  
      console.log('Transaction Details:', {
        downloadURL: downloadURL,
        userInfo: userInfo,
        cartItems: cart.items,
      });
  
      setTransactionDetails({
        downloadURL: downloadURL,
        userInfo: userInfo,
        cartItems: cart.items,
      });
  




      // useEffect(() => {
      //   try {
      //     const storedCart = localStorage.getItem('cartItems');
      //     if (storedCart) {
      //       const parsedCart = JSON.parse(storedCart);
      //       cart.setCartItems(parsedCart);
      //     }
      //   } catch (error) {
      //     console.error('Error loading cart items from local storage:', error);
      //   } ]\

      
      // useEffect(() => {
      //   try {
      //     localStorage.setItem('cartItems', JSON.stringify(cart.items));
      //   } catch (error) {
      //     console.error('Error saving cart items to local storage:', error);
      //   }
      // }, [cart.items]);



      setSuccessfully(true);
      // Email functionality removed
      alert('Transaction image shared successfully!');
    } catch (error) {
      setError('Error sharing transaction image. Please try again.');
      console.error('Error sharing transaction image:', error);
    }



    


  };

  return (
    <>
      <div className="showButton">
        <h4 style={{ zIndex: '1111111111' }} className="add_to_cart" onClick={handleShow}>
          <img src={carticon} alt="cart" width={20}/>
          <span className="products_Add_cart"><p> {productsCount}</p></span>
        </h4>
      </div>
      <Modal className="modal_top" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Shopping Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body className="Items_carts" ref={modalRef}>
          {productsCount > 0 ? (
            <>
              <h1 className="items_text_head"></h1>
              {cart.items.map((currentProduct, idx) => (
                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
              ))}

              <p>
                Kindly transfer the total amount of {typeof cart.getTotalCost() === 'number' ? `#${cart.getTotalCost().toFixed(2)}` : 'Invalid Value'} to the provided account details. After completing the transaction, please return to share your payment receipt. Thank you.
              </p>

              <div className="account_number">
                <p className='account_text'>Bank Name</p>
                <div className="account-input">
                  <input
                    type="text"
                    value="9 payment Service Bank"
                    readOnly
                    onClick={(e) => {
                      e.target.select();
                      document.execCommand('copy');
                      alert('Account number copied to clipboard!');
                    }}
                  />
                </div>
              </div>
              <div className="account_number">
                <p className='account_text'>Account Name</p>
                <div className="account-input">
                  <input
                    type="text"
                    value="Kokoete Bassey Asuquo"
                    readOnly
                    onClick={(e) => {
                      e.target.select();
                      document.execCommand('copy');
                      alert('Account number copied to clipboard!');
                    }}
                  />
                </div>
              </div>
              <div className="account_number">
                <p className='account_text'>Account Number</p>
                <div className="account-input">
                  <input
                    type="text"
                    value="6091249205"
                    readOnly
                    onClick={(e) => {
                      e.target.select();
                      document.execCommand('copy');
                      alert('Account number copied to clipboard!');
                    }}
                  />
                  <div className="copy-icon">
                    <FaCopy onClick={(e) => handleCopyClick(e)} />
                  </div>
                </div>
              </div>

              <input className='choose_file' type="file" accept="image/*" onChange={handleImageChange} />
            <div className="enter_details">
              <h3>Enter Your Information</h3>
            </div>
            <div className="account-input">
                <label htmlFor="fullName">Full Name:</label>
                <input
                className='fullname_input'
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={userInfo.fullName} 
                  onChange={handleUserInfoChange}
                />
              </div>

              <div className="account-input">
                <label htmlFor="emailAddress">Email Address:</label>
                <input
                  type="email"
                  id="emailAddress"
                  name="emailAddress"
                  value={userInfo.emailAddress}
                  onChange={handleUserInfoChange}
                />
              </div>

              <div className="account-input">
                <label htmlFor="homeAddress">Home Address:</label>
                <input
                type="address"
                  id="homeAddress"
                  name="homeAddress"
                  value={userInfo.homeAddress}
                  onChange={handleUserInfoChange}
                />
              </div>

              <div className="account-input">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={userInfo.phoneNumber}
                  onChange={handleUserInfoChange}
                />
              </div>

                <div className="share_transaction_details_head">
        <Button className='share_transaction_details' variant="primary" onClick={handleShareTransactionDetails}>
                  Share Your Transaction Details
        </Button>
       </div>
            </>
          ) : (
            <h1>There are no items in your cart!</h1>
          )}

          <div className="user_information_header">
            <h3>
              Please be aware that the shipping fee for destinations outside Lagos is #3000
          </h3>
        
              <hr />
           
          </div>
             
       {transactionDetails && <Transaction transactionDetails={transactionDetails} />}

       {successfully &&(
            <div className="confirm_transaction_heading">
                <h1>Transactions shared successfully!!</h1>
                <NavLink to="/" >
                    <Button >
                        Ok
                    </Button>
                </NavLink>
            </div>
       )} 
        </Modal.Body>
      </Modal>
      {error && <div className="error-message">{error}</div>}
    </>
  );
}

export default NavbarComponent;
