import React from 'react'
import './styles/home.css'
import Vector from "./images/Vector.png"
import Vector1 from "./images/Vector (1).png"
import Vector2 from "./images/Vector (2).png"
import Vector3 from "./images/Vector (3).png"
import Vector4 from "./images/Vector (4).png"
import Vector5 from "./images/Vector (5).png"
import Vector6 from "./images/Vector (6).png"
import Vector7 from "./images/Vector (7).png"
import Vector8 from "./images/Vector (8).png"
import Vector9 from "./images/Vector (9).png"
import Vector10 from "./images/Vector (10).png"
import Vector11 from "./images/Vector (11).png"
import Vector12 from "./images/Vector (12).png"
import Vector13 from "./images/Vector (13).png"
import scissor from "./images/scissor.svg"
import scissor1 from "./images/scissor-remove.png"

export default function Background() {
  return (
    <div className="web_board_head">
        {/* <div className="vector_png_head"> */}
            {/* <div className="vector_png">
                <img src={Vector1} alt="Vector" height={400} width={400}/>
            </div>
            <div className="vector_png">
                <img src={Vector} alt="Vector1" height={400} width={400}/>
            </div>
            <div className="vector_png">
                <img src={Vector2} alt="Vector2" height={400} width={400}/>
            </div> */}
            {/* <div className="vector_png bg">
                <img src={Vector3} alt="Vector3" height={400} width={400}/>
            </div>
            <div className="vector_png bg1">
                <img src={Vector4} alt="Vector4" height={400} width={400}/>
            </div>
            <div className="vector_png bg2">
                <img src={Vector5} alt="Vector5" height={400} width={400}/>
            </div>
            <div className="vector_png bg3">
                <img src={Vector6} alt="Vector6" height={400} width={400}/>
            </div>
            <div className="vector_png bg4">
                <img src={Vector7} alt="Vector7" height={400} width={400}/>
            </div>
            <div className="vector_png bg5">
                <img src={Vector8} alt="Vector8" height={400} width={400}/>
            </div> */}
            <div className="vector_png bg6">
                <img src={scissor1} alt="scissor" height={600} width={600}/>
            </div>
            {/* <div className="vector_png bg6">
                <img src={scissor1} alt="scissor1" height={400} width={400}/>
            </div> */}
            {/* <div className="vector_png bg7">
                <img src={Vector10} alt="Vector10" height={400} width={400}/>
            </div>
            <div className="vector_png bg8">
                <img src={Vector11} alt="Vector11" height={400} width={400}/>
            </div>
            <div className="vector_png bg9">
                <img src={Vector12} alt="Vector12" />
            </div>
            <div className="vector_png bg10">
                <img src={Vector13} alt="Vector13"/>
            </div>
            <div className="vector_png bg11">
                <img src={Vector10} alt="Vector10" width={400}/>
            </div> */}
        {/* </div> */}
    </div>
  )
}
