import React from 'react';
import './styles/AboutU.css'


const AboutUs = () => {
  return (
    <div className="about-us-container">
      <h1>ABOUT US</h1>
      <p>
        At Koksi Beauty Store, we bring the beauty salon experience directly to 
        your doorstep with our exceptional home services.
        Step into a world of luxury, style, and convenience as 
        we cater to all your grooming needs right in the comfort of your home. 
        Our range of services includes barbering, nail artistry, 
        professional makeup, accessory shopping, and premium barbering tools - 
        all delivered with the utmost care and expertise.


       <span className='expert'>The Koksi Beauty Store Difference:</span> 
    At Koksi Beauty Store, we understand that your time is valuable, 
    and sometimes visiting a salon can be a challenge amidst your busy schedule. 
    That's why we've designed our home services to offer you the same level of excellence 
    and professionalism you'd expect from a high-end salon, 
    but with the added convenience of not leaving your home.
       
       
    <span className='expert'>Expert Stylists at Your Service:</span> 
        Our team of expert stylists, barbers, nail technicians, 
        and makeup artists are dedicated to providing you with top-notch 
        services tailored to your unique preferences. Whether you're looking 
        for a sharp haircut, a dashing beard trim, fabulous nails, or a glamorous 
        makeover, our skilled professionals will work their magic to leave you 
        feeling confident and looking fantastic.
    
   
    <span className='expert'>Beauty and Beyond:</span> 
    Beyond our exceptional beauty services, 
    Koksi Beauty Store also offers a curated collection of fashionable accessories to 
    add that extra touch of elegance to your style. From trendy jewelry to chic 
    scarves and everything in between, our accessory selection will leave you spoilt for choice.
   
   
    <span className='expert'>Premium Barbering Tools Delivered:</span> 
    For grooming enthusiasts who take pride in maintaining their look, 
    Koksi Beauty Store offers a range of premium barbering tools and equipment. 
    From precision clippers to professional-grade razors, we provide high-quality 
    tools that help you achieve salon-quality results from the comfort of your home.
  
  
   <span className='expert'>Experience the Ultimate Home Service:</span> 
   Koksi Beauty Store takes pride in offering the ultimate home service experience, 
   ensuring you enjoy every moment of your pampering session without any hassle. 
   Our team arrives fully equipped with all the necessary tools and products, 
   creating a luxurious salon ambiance in your home.
  
  
   <span className='expert1'>Book Your Koksi Beauty Store Home Service Today:</span> 
    Elevate your grooming routine and indulge in the luxury of our home services. 
    Whether you have a special event to attend, want to treat yourself, 
    or simply need a quick grooming touch-up, Koksi Beauty Store is just a call 
    or click away. Sit back, relax, and let our team of professionals 
    transform your space into a haven of beauty and style.
   
   
   <span className='expert'>   Stay Connected:</span> 
    Stay updated with the latest beauty trends, tips, and exclusive offers by 
    following us on social media and subscribing to our newsletter.
    Discover the Koksi Beauty Store difference today - Where Beauty Comes Home!
      </p>
    </div>
  );
};

export default AboutUs;