import './AirbnbHome.css';
import BookPage from './Book';
import { NavLink } from 'react-router-dom';
import Background from "../component/Background";
import { useState } from 'react';
import { app} from "../firebaseConfig"; // Import the Firebase app and database
import NavBar1 from './NavBar1';
// import { PaystackButton } from 'react-paystack';
import { push, ref, set, getDatabase } from 'firebase/database';

// const config = {
//   reference: (new Date()).getTime().toString(),
//   email: "user@example.com",
//   amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
//   publicKey: 'pk_live_8f12983f956f1b2d1bb9dcc5994fb890bdb9640a',
// };

const AirbnbHome = () => {
  const [booked, setBooked] = useState(false)
  const [bookedSlip, setBookedSlip] = useState(true)


  // const handlePaystackSuccessAction = (reference) => {
  //   // Implementation for whatever you want to do with reference and after success call.
  //   console.log(reference);
  // };

  // // you can call this function anything
  // const handlePaystackCloseAction = () => {
  //   // implementation for  whatever you want to do when the Paystack dialog closed.
  //   console.log('closed')
  // }

  // const componentProps = {
  //     ...config,
  //     text: 'Paystack Button Implementation',
  //     onSuccess: (reference) => handlePaystackSuccessAction(reference),
  //     onClose: handlePaystackCloseAction,
  // };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        const book_date = document.getElementById('book-date');
        const book_time = document.getElementById('book-time');
        
        const email = document.getElementById('email');
        const name = document.getElementById('name');
        const phone_number = document.getElementById('phone_number');


        if(!name.value || !book_date.value || !book_time.value || !email.value || !phone_number.value){
            alert('Please fill all the fields');
        }else{
            writeUserData();
          // const db = getDatabase(app); //

            function writeUserData() {
              const db = getDatabase(app);
              const appointmentsRef = ref(db, 'data/appointments'); // Change 'data/' to your desired location
            
              const newAppointmentRef = push(appointmentsRef); // Generate a new unique key
            
              const appointmentData = {
                booking_Name: name.value,
                booking_Date: book_date.value,
                booking_Time: book_time.value,
                email: email.value,
                phone_number: phone_number.value,
              };
            
              set(newAppointmentRef, appointmentData)
              .then(() => {
                alert('Your Appointment has been booked Successfully');
                setBooked(true)
                setBookedSlip(false)
              })
                .catch((error) => {
                  console.error('Error writing appointment data:', error);
                });
            }
          }
    }
  return (
      <>
      {booked && (
        <BookPage />
      ) }
        
        <NavBar1 />
        {bookedSlip && (<div className="home_header container">
      <div className="login_header">
        <div className="signup_head">
          <div className="signup_body">
            <NavLink to="/">
            <div className="cancelling_form">x</div>
          </NavLink>
          <div className="txt">Schedule Your Appointment</div>
          <form action="" onSubmit={handleSubmit}>
          <div className="enter_time">Enter your Name</div>
          <div className="select_input">
              <input type="text" name="" id="name" placeholder="Name"/>
            </div>
          <div className="enter_time">Enter Your Email</div>
          <div className="select_input">
              <input type="email" name="" id="email" placeholder="Email"/>
            </div>
          <div className="enter_time">Enter Your Phone Number</div>
            <div className="select_input">
              <input type="phone" name="" id="phone_number" 
              placeholder="phone"/>
            </div>
          <div className="enter_time">Enter Your Prefered date</div>
            <div className="select_input">
              <input type="date" name="" id="book-date"/>
            </div>
            <div className="enter_time">Enter Your Prefered Time</div>
            <div className="select_input">
              <input type="time" name="" id="book-time" />
            </div>
            <div className="signIn_bt">
            {/* <PaystackButton {...componentProps} /> */}
                <button className="account_signup" type="submit">Book Appointment</button>
            </div>
          </form>
          </div>
        </div>
      </div>
      <Background />
      </div>)}
      

      </>
  );
};
export default AirbnbHome;