import React, { useState } from 'react'
import Background from './Background'
import "./styles/signup.css"
import emailjs from 'emailjs-com';
import NavBar from './NavBar1';





export default function Signup() {

  const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
      date: '',
    });
    
     const [loading, setLoading] = useState(false);

      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

    
        const { name, email, message, date } = formData;
    
        // Replace 'YOUR_EMAILJS_SERVICE_ID', 'YOUR_EMAILJS_TEMPLATE_ID', and 'YOUR_EMAILJS_USER_ID' with your actual values
        emailjs.send(
          'service_abshnmn',
          'template_4pdsg6h',
          {
            from_name: name,
            from_email: email,
            message: message,
            date: date,
          },
          '-okGYnDquvUmor22i'
        )
        .then((response) => {
          setLoading(false);
          console.log('Email sent successfully!', response);
          alert("Thank you. I will get back to you as soon as possible.");
          // Add any success message or behavior here
        })
        .catch((error) => {
          setLoading(false);
          alert("Ahh, something went wrong. Please try again.");
          console.error('Error sending email:', error);
          // Add any error message or behavior here
        });
      };








  return (
    <>
       <div className="home_container">
           <NavBar />

            <div className="select_section">
            <h1>Send Email</h1>
            <form onSubmit={handleSubmit}>

                <div className="select_game_bt">
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder='Name'/>
                </div>
                <div className="select_game_bt">
                <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        placeholder='Email' />
                </div>
                <div className="select_game_bt">
                  <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      placeholder='Type your message'/>
                  </div>
                <div className="signIn_bt">
                <button
                    type='submit'
                    // className='contact_button'
                    >
                    {loading ? "Sending..." : "Send"}
                </button>
                </div>
                </form>
            </div>

            <div className="bottom_line">
                <hr />
            </div>
        <Background />

        </div>

    </>
  )
}
