import React, { useState, useEffect } from "react";
import { Routes, Route, Link, NavLink, Navigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./firebaseConfig";
import CartProvider from "./CartContext";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ChatBot from "./component/Home";
import Blog from "./pages/Blog";
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import AboutUs from "./component/AboutUs";
import BarbingSection from "./pages/BarbingSection";
import ExpertIcon from "./component/ExpertIcon";
import BookingForm from "./component/BookingForm";
import Background from "./component/Background";
import Error from "./component/Error";
import SignIn from "./component/SignIn";
import BookingSlip from "./component/AirbnbHome";
import Book from "./component/Book";
import Maintain from "./pages/Maintain";
import BarberAccessories from "./component/Barber_Accessories";
import Footer from "./component/Footer";
import HomeService from "./component/HomeService";
import SideMenu from "./component/SideMenu";
import Cancel from "./pages/Cancel";
import Store from "./pages/Store";
import NailSection from "./pages/NailSection";
import Success from "./pages/Success";
import NewsletterSignup from './pages/NewsletterSignup'; 
import Account from "./component/Account";
import Transaction from "./component/Transaction";
import ReferredUserLogin from "./component/ReferredUserLogin";


function App() {

  const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
  const [userName, setUserName] = useState("");

  const handleLogin = (name) => {
    setIsAuth(true);
    setUserName(name);
    window.location.pathname = "/";
  };


  const [hideblog, setHideBlog] = useState(false)
  const [hideblog2, setHideBlog2] = useState(true)
  const [isOpenBlog, setisOpenBlogHideBlog2] = useState(false)



  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7123335126170285";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.clear();
      setIsAuth(false);
      window.location.pathname = "/login";
    });
  };

  const handleMenu = () => {
    if(!isOpenBlog){
      setisOpenBlogHideBlog2(true)
    } else{
      setisOpenBlogHideBlog2(false)
    }
  }

  const handleHideBlog = () =>{
    setHideBlog(true)
    setHideBlog2(false)
  }

  const handleHideBlog2 = () =>{
    setHideBlog(false)
    setHideBlog2(true)
  }

  const handleCancel = () => {
    setisOpenBlogHideBlog2(false)
  }


  return (
    <>
      <CartProvider>
        <Container>
          <div className="App">
            {loading && (
              <div className="mobile-preloader">
                <div className="mobile-loader">
                  <img
                    src="https://ik.imagekit.io/eitvb0lyq/koko%20canva%20(1).png?updatedAt=1698762961621"
                    alt=""
                    loading="lazy"
                    width={1000}
                  />
                </div>
              </div>
            )}
            <SideMenu />

            <div>
              <Routes>
                <Route path="/SideMenu" element={<SideMenu />} />
                <Route path="/success" element={<Success />} />
                <Route path="/cancel" element={<Cancel />} />
                <Route path="Store" element={<Store />} />
                <Route path="/BookingForm" element={<BookingForm />}></Route>
                <Route path="/AboutUs" element={<AboutUs />}></Route>
                <Route path="BookingSlip" element={<BookingSlip />}></Route>
                <Route
                  path="/BarbingSection"
                  element={<BarbingSection />}
                ></Route>
                <Route path="/ExpertIcon" element={<ExpertIcon />}></Route>
                <Route path="/SignIn" element={<SignIn />}></Route>
                <Route path="/HomeService" element={<HomeService />}></Route>
                <Route path="/Book" element={<Book />}></Route>
                <Route path="/Background" element={<Background />}></Route>
                <Route path="/NailSection" element={<NailSection />}></Route>
                <Route path="/Maintain" element={<Maintain />}></Route>
                <Route path="/Footer" element={<Footer />}></Route>
                <Route path="/Account" element={<Account />}></Route>
                <Route path="/Transaction" element={<Transaction />}></Route>
                <Route path="/ReferredUserLogin" element={<ReferredUserLogin />}></Route>
                <Route
                  path="/NewsletterSignup"
                  element={<NewsletterSignup />}
                ></Route>
                <Route
                  path="/BarberAccessories"
                  element={<BarberAccessories />}
                ></Route>
                <Route path="*" element={<Error />}></Route>
                <Route path="/" element={<ChatBot isAuth={isAuth} userName={userName} />} />

                <Route
                  path="/Blog"
                  element={
                    isAuth ? (
                      <Blog isAuth={isAuth} />
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  }
                />

                <Route
                  path="/createpost"
                  element={<CreatePost isAuth={isAuth} />}
                />
                 <Route
                  path="/login"
                  element={<Login setIsAuth={setIsAuth} onLogin={handleLogin} />}
                />
              </Routes>
            </div>
          </div>
        </Container>
      </CartProvider>
    </>
  );
}

export default App;