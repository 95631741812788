import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import { useFirebaseAuth } from '../FirebaseAuthContext/FirebaseAuthContext';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider, db } from '../firebaseConfig';
import { FaCopy } from 'react-icons/fa';
import { collection, doc, getDoc, updateDoc, setDoc, increment } from 'firebase/firestore';




const ReferredUserLogin = () => {
  const { user } = useFirebaseAuth();
  const [referralLink, setReferralLink] = useState('');
  const [referral, setReferral] = useState(true);
  const navigate = useNavigate(); // Add this line




  const handleCancelReferal = () =>{
    setReferral(null)
  }




  useEffect(() => {
    // Check if the user is logged in
    if (user) {
      // Add logic to give points to the referrer
      givePointsToReferrer(user.uid);

      // Generate and display the referral link
      generateReferralLink(user.uid);
    }
  }, [user]);

  const generateReferralLink = (userId) => {
    const referralLink = `https://koksibeautystore.com/login?code=${userId}`;
    setReferralLink(referralLink);
  };

  const givePointsToReferrer = async (referredUserId) => {
    // Assume you have a 'referralPoints' collection in Firestore
    const referralPointsCollection = collection(db, 'referralPoints');

    try {
      // Check if the referrer already has points
      const referrerDoc = await getDoc(doc(referralPointsCollection, referredUserId));

      if (referrerDoc.exists()) {
        // Update existing points
        await updateDoc(doc(referralPointsCollection, referredUserId), {
          points: increment(1), // Adjust the points as needed
        });
      } else {
        // Create a new document for the referrer
        await setDoc(doc(referralPointsCollection, referredUserId), {
          points: 1, // Adjust the initial points as needed
        });
      }

      console.log('Points added to the referrer successfully.');
    } catch (error) {
      console.error('Error giving points to referrer:', error);
    }
  };

  const handleSignIn = async () => {
    try {
      // Sign in with Google Popup
      await signInWithPopup(auth, provider);

      // After successful login, navigate to the referral link or any other route
      navigate('/referral');
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const handleCopyToClipboard = () => {
    const input = document.getElementById('referralLink');
    input.select();
    document.execCommand('copy');
    alert('Copied to clipboard!');
  };

  return (

    <>
    {referral && (<div className='referall_page'>
      {user ? (
        <div >
          <h1 onClick={handleCancelReferal}  className='cancel_referal'>&lt;</h1>
          <p className='referall_name'>Hello, {user.displayName}! </p>
            <p>
             Copy and share your unique link with friends to give them a special 20% discount on their first purchase. 
              As a token of our appreciation, you'll also receive a 20% discount for each successful referral!
            </p>
            <p>Spread the word and enjoy exclusive discounts together!</p>
          <div>
            <label>Your Referral Link:</label>
            <div className='referralLink'>
              <input type="text" id="referralLink" value={referralLink} readOnly />
              <button onClick={handleCopyToClipboard}><FaCopy /></button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <button onClick={handleSignIn}>Sign In with Google</button>
        </div>
      )}
    </div>)
  
  }
  </>
    
  );
};

export default ReferredUserLogin;
