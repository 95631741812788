import React, { useState } from "react";
import { auth, provider } from "../firebaseConfig";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Login({ setIsAuth, onLogin,
   referrerName 
  }) {
  const [showWelcome, setShowWelcome] = useState(false);
  const [userName, setUserName] = useState("");
  let navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        localStorage.setItem("isAuth", true);
        setIsAuth(true);
        setUserName(user.displayName || "Guest");
        setTimeout(() => {
          setShowWelcome(true);
        }, 80000);

        setShowWelcome(false);
        navigate("/");

        onLogin(user.displayName || "Guest", user.displayName || "Guest");
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error.message);
      });
  };

  return (
    <div className="loginPage">
      {/* Display the welcome message when showWelcome is true */}
      {showWelcome && (
        <div className="welcome-message">
          <p>Welcome to Koksi Beauty Store, {userName || "Guest"}!</p>
        </div>
        
      )}
        {referrerName && 
        <p>You were invited by {referrerName}</p>
       } 
      <p>Sign In With Google to Continue</p>
      <button className="login-with-google-btn" onClick={signInWithGoogle}>
        Sign in with Google
      </button>
    </div>
  );
}

export default Login;
